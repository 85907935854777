/**
 * Generated by orval 🍺
 * Do not edit manually.
 * api API
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryClient,
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { EntityPermissionResponse } from '../models/entityPermissionResponse';
import type { EntityPermissionsRequest } from '../models/entityPermissionsRequest';
import type { HitsEntityPermissions } from '../models/hitsEntityPermissions';
import { executeFetch } from '../../utils/fetch/fetch';
import type { ErrorType } from '../../utils/fetch/fetch';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const fetchEntityPermissions = (
  entityPermissionsRequest: EntityPermissionsRequest,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<EntityPermissionResponse>(
    {
      url: `/api/custom-interfaces/permissions/entity-permissions`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: entityPermissionsRequest,
    },
    options,
  );
};

export const getFetchEntityPermissionsQueryKey = (
  entityPermissionsRequest: EntityPermissionsRequest,
) => {
  return [
    `/api/custom-interfaces/permissions/entity-permissions`,
    entityPermissionsRequest,
  ] as const;
};

export const getFetchEntityPermissionsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchEntityPermissions>>,
  TError = ErrorType<unknown>,
>(
  entityPermissionsRequest: EntityPermissionsRequest,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof fetchEntityPermissions>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchEntityPermissionsQueryKey(entityPermissionsRequest);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchEntityPermissions>>> = () =>
    fetchEntityPermissions(entityPermissionsRequest, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchEntityPermissions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchEntityPermissionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchEntityPermissions>>
>;
export type FetchEntityPermissionsQueryError = ErrorType<unknown>;

export const useFetchEntityPermissions = <
  TData = Awaited<ReturnType<typeof fetchEntityPermissions>>,
  TError = ErrorType<unknown>,
>(
  entityPermissionsRequest: EntityPermissionsRequest,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof fetchEntityPermissions>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchEntityPermissionsQueryOptions(entityPermissionsRequest, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchFetchEntityPermissions = async <
  TData = Awaited<ReturnType<typeof fetchEntityPermissions>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  entityPermissionsRequest: EntityPermissionsRequest,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof fetchEntityPermissions>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getFetchEntityPermissionsQueryOptions(entityPermissionsRequest, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const fetchUserEntityPermissions = (options?: SecondParameter<typeof executeFetch>) => {
  return executeFetch<HitsEntityPermissions>(
    { url: `/api/custom-interfaces/permissions/user-entity-permissions`, method: 'POST' },
    options,
  );
};

export const getFetchUserEntityPermissionsQueryKey = () => {
  return [`/api/custom-interfaces/permissions/user-entity-permissions`] as const;
};

export const getFetchUserEntityPermissionsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchUserEntityPermissions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof fetchUserEntityPermissions>>, TError, TData>
  >;
  request?: SecondParameter<typeof executeFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFetchUserEntityPermissionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchUserEntityPermissions>>> = () =>
    fetchUserEntityPermissions(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchUserEntityPermissions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchUserEntityPermissionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchUserEntityPermissions>>
>;
export type FetchUserEntityPermissionsQueryError = ErrorType<unknown>;

export const useFetchUserEntityPermissions = <
  TData = Awaited<ReturnType<typeof fetchUserEntityPermissions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof fetchUserEntityPermissions>>, TError, TData>
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchUserEntityPermissionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchFetchUserEntityPermissions = async <
  TData = Awaited<ReturnType<typeof fetchUserEntityPermissions>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof fetchUserEntityPermissions>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getFetchUserEntityPermissionsQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};
