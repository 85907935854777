import _snakeCase from 'lodash/snakeCase';
import _groupBy from 'lodash/groupBy';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';
import type { TFunction } from '@unifyapps/i18n/client';
import type useModuleRoute from '@/modules/sidenav/hooks/useModuleRoute';
import type { NavItem, NavItemType, SideNavItem } from './context/AppSidenavContext';
import { navItemType } from './context/AppSidenavContext';

/**
 * 1. When saving in object manager, the types are converted to small case. ex. USER_MENU is converted to userMenu. Convert it back
 * 2. Add url to the side nav items, if applicable
 * @param t
 * @param sideNavItems
 * @param getModuleRoute
 */
export function getSideNavItemsWithUrl(
  t: TFunction,
  getModuleRoute: ReturnType<typeof useModuleRoute>['getModuleRoute'],
  sideNavItems: SideNavItem[] = [],
) {
  return sideNavItems.map((item) => {
    const sideNavItem = item.properties;
    const type = _snakeCase(sideNavItem.type).toUpperCase() as NavItemType;
    let url = sideNavItem.url;
    // here we're running the best effort translation on the name in sidebar
    const name = t(sideNavItem.name);

    if (type === 'APPLICATION_PAGE') {
      url = `/page/${sideNavItem.interfaceId}/${sideNavItem.interfacePageSlug}`;
    } else if (type === 'URL' && sideNavItem.moduleId) {
      url = getModuleRoute(sideNavItem.moduleId);
    }

    return {
      ...sideNavItem,
      type,
      url,
      name,
    };
  });
}

/**
 * Remove items with missing or invalid data
 * @param sideNavItems
 */
export function getSanitisedNavItems(sideNavItems: NavItem[] = []) {
  const groupedItems = getItemsByGroup(sideNavItems);

  const sanitisedNavItems = sideNavItems.filter((item) => {
    switch (item.type) {
      case 'GROUP':
        if (_isEmpty(groupedItems[item.id])) {
          console.debug('[Sidenav error]: Group item with no children', item);
          return false;
        }
        break;
      case 'URL':
        if (!item.url) {
          console.debug('[Sidenav error]: URL item with no URL or module', item);
          return false;
        }
        break;
      case 'APPLICATION_PAGE':
        if (!item.interfaceId || !item.interfacePageSlug) {
          console.debug(
            '[Sidenav error]: Application page item with no interfaceId or interfacePageSlug',
            item,
          );
          return false;
        }
        break;
      case 'ACTION':
        if (item.id !== 'notifications') {
          console.debug('[Sidenav error]: Action item with unsupported id', item);
          return false;
        }
        break;
      default:
        break;
    }
    return true;
  });

  return sanitisedNavItems;
}

/**
 * Group the side nav items by valid parent
 * @param updatedSideNavItems
 */
export function getItemsByGroup(updatedSideNavItems: NavItem[] = []) {
  return _omit(_groupBy(updatedSideNavItems, 'parent'), ['undefined', '']);
}

export function getAdaptedSideNavItems(
  updatedSideNavItems: NavItem[] = [],
  groupedItems: Record<string, NavItem[] | undefined> = {},
): NavItem[] {
  return updatedSideNavItems
    .map((item) => {
      if (item.parent || !navItemType.includes(item.type)) {
        return null;
      }
      return {
        ...item,
        ...(groupedItems[item.id] && { items: groupedItems[item.id] }),
      };
    })
    .filter(Boolean) as NavItem[];
}

/**
 * Categorise the side nav items based on placement - primary and secondary
 * @param navItems
 */
export function getCategorisedSideNavItems(navItems: NavItem[] = []) {
  const updatedPrimaryNavItems: NavItem[] = [];
  const updatedSecondaryNavItems: NavItem[] = [];

  navItems.forEach((navItem) => {
    if (navItem.placement === 'secondary') {
      updatedSecondaryNavItems.push(navItem);
    } else {
      updatedPrimaryNavItems.push(navItem);
    }
  });

  return {
    primaryNavItems: updatedPrimaryNavItems,
    secondaryNavItems: updatedSecondaryNavItems,
  };
}
