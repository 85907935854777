import { prefetchSearchEntities } from '@unifyapps/network/generated/entity-rest-api/entity-rest-api';
import { Order } from '@unifyapps/network/generated/models/order';
import { UAEntityTypes } from '@unifyapps/defs/types/entities';
import type { QueryClient } from '@unifyapps/network/react-query';
import { HTTP_HEADERS } from '@unifyapps/network/auth/const';

export const getSideNavParams = () => {
  return {
    sorts: [
      {
        field: 'properties.order',
        order: Order.ASC,
      },
    ],
    page: {
      limit: 1000,
      offset: 0,
    },
  };
};

export async function prefetchSideNavItems(queryClient: QueryClient) {
  await prefetchSearchEntities(queryClient, UAEntityTypes.Navigation, getSideNavParams(), {
    request: {
      headers: {
        // Since navigation is global, we don't need to pass projectId so inside projects, the same navigation gets shown as in the global context
        [HTTP_HEADERS.ProjectId]: '',
      },
    },
  });

  return queryClient;
}
