export enum ConnectorAuthenticationDetailsTabsEnum {
  'Details' = 'details',
  'Inputs' = 'inputs',
  'Outputs' = 'outputs',
}

export enum AuthType {
  Token = 'TOKEN',
  JWT = 'JWT',
  OAuth = 'OAUTH',
  Basic = 'BASIC',
}
