import type { StyleOverrides } from '@mui/joy';
import type { ButtonGroupSlot } from '@mui/joy/ButtonGroup';
import type { Theme } from '@unifyapps/style/joy/theme';

export const getButtonGroupStyleOverrides = (): StyleOverrides<ButtonGroupSlot, unknown, Theme> => {
  return {
    root: () => {
      return [
        {
          // joy automatically adjusts styles for first and last buttons and orientation based on these variables
          '--ButtonGroup-separatorSize': '1px',
          '--ButtonGroup-separatorColor': 'var(--border-primary)',
          '--ButtonGroup-radius': 'var(--radius-sm)',
        },
      ];
    },
  };
};
