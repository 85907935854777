import type { CssVarsThemeOptions } from '@mui/joy/styles/extendTheme';
import { extendTheme, themeOptions } from '@unifyapps/style/joy/theme';
import { getChipStyleOverrides } from '../components/Chip/style-overrides';
import { getTextareaStyleOverrides } from '../components/Textarea/style-overrides';
import { getCheckboxStyleOverrides } from '../components/Checkbox/style-overrides';
import { getButtonStyleOverrides } from '../components/Button/style-overrides';
import { getDividerStyleOverrides } from '../components/Divider/style-overrides';
import { getIconButtonStyleOverrides } from '../components/IconButton/style-overrides';
import { getButtonGroupStyleOverrides } from '../components/ButtonGroup/style-overrides';
import { getToggleButtonGroupStyleOverrides } from '../components/DeprecatedToggleButtonGroup/style-overrides';
import { getInputStyleOverrides } from '../components/Input/style-overrides';
import { getSwitchStyleOverrides } from '../components/Toggle/style-overrides';
import { getRadioStyleOverrides } from '../components/Radio/style-overrides';
import { getTabStyleOverrides } from '../components/Tab/style-overrides';
import { getTabListStyleOverrides } from '../components/TabList/style-overrides';
import { getTabsStyleOverrides } from '../components/Tabs/style-overrides';
import { getTooltipStyleOverrides } from '../components/Tooltip/style-overrides';
import {
  getModalDialogStyleOverrides,
  getModalRootStyleOverrides,
} from '../_components/Modal/style-overrides';
import {
  getAutocompleteOptionStyleOverrides,
  getAutocompleteStyleOverrides,
} from '../components/Autocomplete/style-overrides';
import { getBreadcrumbsStyleOverrides } from '../components/Breadcrumbs/style-overrides';
import { getAvatarStyleOverrides } from '../components/Avatar/style-overrides';

export interface GetThemeProps {
  fontFamily: CssVarsThemeOptions['fontFamily'] & {
    text?: string;
  };
}

// default theme https://github.com/mui/material-ui/blob/b7fea89bc232622546b6bc9675a818bfa95a8376/packages/mui-joy/src/styles/extendTheme.ts#L341
const getTheme = ({ fontFamily = {} }: GetThemeProps): ReturnType<typeof extendTheme> =>
  extendTheme({
    // joy ui constructs its theme based on themeOptions
    // this is no longer needed as we are creating our own theme / css variables
    // set ugly values in themeOptions to find out what styles are still applied by joy ui
    ...themeOptions,
    cssVarPrefix: '', // To remove the `--joy-` prefix from the CSS variables
    fontFamily,
    components: {
      JoyAvatar: {
        styleOverrides: getAvatarStyleOverrides(),
      },
      JoyButton: {
        styleOverrides: getButtonStyleOverrides(),
      },
      JoyIconButton: {
        styleOverrides: getIconButtonStyleOverrides(),
      },
      JoyButtonGroup: {
        styleOverrides: getButtonGroupStyleOverrides(),
      },
      JoyToggleButtonGroup: {
        styleOverrides: getToggleButtonGroupStyleOverrides(),
      },
      JoyChip: {
        styleOverrides: getChipStyleOverrides(),
      },
      JoyInput: {
        styleOverrides: getInputStyleOverrides(),
      },
      JoyTextarea: {
        styleOverrides: getTextareaStyleOverrides(),
      },
      JoyAutocomplete: {
        styleOverrides: getAutocompleteStyleOverrides(),
      },
      JoyAutocompleteOption: {
        styleOverrides: getAutocompleteOptionStyleOverrides(),
      },
      JoyCheckbox: {
        styleOverrides: getCheckboxStyleOverrides(),
      },
      JoySwitch: {
        styleOverrides: getSwitchStyleOverrides(),
      },
      JoyRadio: {
        styleOverrides: getRadioStyleOverrides(),
      },
      JoyTooltip: {
        styleOverrides: getTooltipStyleOverrides(),
      },
      JoyTab: {
        styleOverrides: getTabStyleOverrides(),
      },
      JoyTabs: {
        styleOverrides: getTabsStyleOverrides(),
      },
      JoyTabList: {
        styleOverrides: getTabListStyleOverrides(),
      },
      JoyBreadcrumbs: {
        styleOverrides: getBreadcrumbsStyleOverrides(),
      },
      JoyDivider: {
        styleOverrides: getDividerStyleOverrides(),
      },
      JoyModal: {
        styleOverrides: getModalRootStyleOverrides(),
      },
      JoyModalDialog: {
        styleOverrides: getModalDialogStyleOverrides(),
      },
    },
  });

export default getTheme;
