export function getEnabledGroups<T extends { items?: object[] }>(
  items: (T & { type: string; enabled?: boolean })[],
) {
  return items
    .filter((item) => {
      if (item.enabled === false) return false;

      // hide group if all items are disabled
      if (item.type === 'GROUP' && !item.items?.length) return false;

      return true;
    })
    .map(({ enabled, ...rest }) => rest) as T[];
}
