'use client';

import { useLocation, useMount } from 'react-use';
import { useDebouncedEffect } from '@react-hookz/web';
import { inIframe } from '@/hook/useLink';

const enum MessageType {
  EmbeddedEvent = 'UNIFYAPPS__EMBEDDED_EVENT',
}

function sendMessage(type: MessageType, data: string) {
  window.parent.postMessage({ source: 'unifyapps', type, data }, '*');
}

function SendEvents() {
  const location = useLocation();
  const pathWithQueryParams = `${location.pathname}${location.search}`;

  useMount(() => {
    // add class for iframe styles
    document.body.classList.add('is-iframe');
  });

  useDebouncedEffect(
    () => {
      sendMessage(MessageType.EmbeddedEvent, pathWithQueryParams);
    },
    [pathWithQueryParams],
    150,
  );

  return null;
}

export default function EmbeddedSDK() {
  // Only enable in iframe
  if (!inIframe()) {
    return null;
  }

  return <SendEvents />;
}
