export enum WorkflowDetailsTabs {
  'Preview' = 'preview',
  'Summary' = 'summary',
  'Runs' = 'runs',
  'Versions' = 'versions',
  'Insights' = 'insights',
  'Alerts' = 'alerts',
  'Settings' = 'settings',
  'Activity' = 'activity',
}

export enum WorkflowBuilderTabs {
  'Builder' = 'builder',
  'Test' = 'test',
}

export enum WorkflowSettingsTabs {
  'Alerts' = 'alerts',
  'Telemetry' = 'telemetry',
}
