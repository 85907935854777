import { useQueryState } from 'nuqs';
import useEventCallback from '@unifyapps/hooks/useEventCallback';

export const RESTORE_VERSION = 'restoreVersion';

export const useRestoreVersion = () => {
  const [restoreVersion, setRestoreVersion] = useQueryState(RESTORE_VERSION);

  return {
    restoreVersion: restoreVersion ? Number(restoreVersion) : undefined,
    setRestoreVersion,
    clearRestoreVersion: useEventCallback(() => {
      void setRestoreVersion(null);
    }),
  };
};
