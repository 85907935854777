import type { AutocompleteSlot } from '@mui/joy/Autocomplete';
import type { AutocompleteOptionSlot } from '@mui/joy/AutocompleteOption';
import type { StyleOverrides, Theme } from '@unifyapps/style/joy/theme';

export const getAutocompleteStyleOverrides = (): StyleOverrides<
  AutocompleteSlot,
  unknown,
  Theme
> => {
  return {
    root: () => {
      return [
        {
          '--Input-decoratorChildOffset': 0,
          '--Input-paddingInline': 0,
          '--Input-gap': 0,
          '--Input-radius': 0,
          '--Input-minHeight': 0,
          // disable joy's focus rings
          '--Input-focusedThickness': 0,
          '--Input-focusedHighlight': 'transparent',

          // disable placeholder color and opacity
          '--Input-placeholderColor': 'unset',
          '--Input-placeholderOpacity': 1,

          // disable gap added in wrapper when multiple option is selection
          '--Autocomplete-wrapperGap': 0,
          boxShadow: 'none',
        },
      ];
    },
  };
};

export const getAutocompleteOptionStyleOverrides = (): StyleOverrides<
  AutocompleteOptionSlot,
  unknown,
  Theme
> => {
  return {
    root: () => {
      return [
        {
          // no need of outline, we are using bg color
          '--focus-outline-offset': 0,
          '--focus-thickness': 0,
        },
      ];
    },
  };
};
