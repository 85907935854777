import type { StyleOverrides, SwitchOwnerState, SwitchSlot, Theme } from '@mui/joy';
import { switchClasses } from '@mui/joy';

export const getSwitchStyleOverrides = (): StyleOverrides<SwitchSlot, SwitchOwnerState, Theme> => {
  return {
    root: ({ ownerState }) => [
      {
        backgroundColor: 'unset',
        // not using cva for these, as joy uses these variables for position, size, and animation of various elements
        '--Switch-gap': 0,
        // @ts-expect-error xs does not exist on Joy UI's SwitchProps. and we have not overridden the type
        ...(ownerState.size === 'xs' && {
          '--Switch-trackWidth': '1.75rem',
          '--Switch-trackHeight': '1rem',
          '--Switch-thumbWidth': '0.75rem',
          '--Switch-thumbSize': '0.75rem',
        }),
        ...(ownerState.size === 'sm' && {
          '--Switch-trackWidth': '2.25rem',
          '--Switch-trackHeight': '1.25rem',
          '--Switch-thumbWidth': '1rem',
          '--Switch-thumbSize': '1rem',
        }),
        ...(ownerState.size === 'md' && {
          '--Switch-trackWidth': '2.75rem',
          '--Switch-trackHeight': '1.5rem',
          '--Switch-thumbWidth': '1.25rem',
          '--Switch-thumbSize': '1.25rem',
        }),
        [`& .${switchClasses.thumb}`]: {
          transition: 'width 0.2s, left 0.2s',
        },
      },
    ],
    track: () => [
      {
        backgroundColor: 'unset',
      },
    ],
    thumb: () => [
      {
        backgroundColor: 'unset',
      },
    ],
    action: () => [
      {
        // focus ring applied to track element via cva
        [`&.${switchClasses.focusVisible}`]: {
          outline: 'none',
        },
      },
    ],
  };
};
