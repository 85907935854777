import type { Theme } from '@unifyapps/style/joy/theme';
import { Client, getClient } from '@/utils/theme/StyleOverride/getClient';
import walkmeTheme from './walkme/theme';
import hdfcTheme from './hdfc/theme';
import lenskartTheme from './lenskart/theme';
import defaultTheme from './default/theme';
import dtTheme from './dt/theme';

const themeCache: Partial<Record<Client, Theme>> = {
  [Client.Walkme]: walkmeTheme,
  [Client.Hdfc]: hdfcTheme,
  [Client.DpDp]: hdfcTheme,
  [Client.Dt]: dtTheme,
  [Client.Lenskart]: lenskartTheme,
};

export const useThemeGenerator = (host?: string | null) => {
  const theme = themeCache[getClient(host)] ?? defaultTheme;

  return {
    theme,
  };
};
