import type { ModuleValues } from '@unifyapps/carbon/consts/modules';
import { Modules } from '@unifyapps/carbon/consts/modules';
import { getAdaptedProjectsRoute } from '@unifyapps/carbon/navigation/project/utils';
import { INTERFACE_BASE_ROUTE } from '@unifyapps/carbon/no-code/utils/interfaceRoutes';
import { useCallback, useMemo } from 'react';
import { DEFAULT_PROJECT_ID } from '@unifyapps/network/utils/consts/project';
import { BASE_ROUTE as API_PLATFORM_BASE_ROUTE } from '@/modules/api-platform/utils/route';
import { getCallableInterfacesRoute } from '@/modules/callable-interfaces/views/CallableInterfacesManager/utils/route';
import { PIPELINE_BASE_ROUTE } from '@/modules/pipelines/utils/getPipelineRoute';
import { DATA_CATALOG_BASE_ROUTE } from '@/modules/data-catalog/utils/route';
import { WORKFLOWS_BASE_ROUTE } from '@/modules/workflows/utils/route';
import { BASE_ROUTE as PROJECTS_BASE_ROUTE } from '@/modules/projects/utils/route';
import { getModelsManagerRoute } from '@/modules/unified-entity-model/utils/route';
import { BASE_ROUTE as AI_AGENTS_BASE_ROUTE } from '@/modules/ai-agents/utils/route/const';
import { getObjectsManagerRoute } from '@/modules/objects/utils/route';
import { getConnectorsRoute } from '@/modules/connectors/utils/route';
import { getConnectionsRoute } from '@/modules/connections/utils/route';
import { getEnvVariableRoute } from '@/modules/settings/views/EnvVariables/views/EnvVariablesManager/utils/route';
import { BASE_ROUTE as EVENT_STREAMS_BASE_ROUTE } from '@/modules/event-streams/utils/route';
import { BASE_ROUTE as TEMPLATES_BASE_ROUTE } from '@/modules/templates/views/TemplatesManager/utils';
import { CAMPAIGN_BASE_ROUTE } from '@/modules/campaign/views/CampaignManager/utils';
import { BASE_ROUTE as SEGMENT_BASE_ROUTE } from '@/modules/segments/views/SegmentsManager/utils';
import { BASE_ROUTE as AI_MODELS_BASE_ROUTE } from '@/modules/ai-models/utils/route';
import { getDecisionTablesManagerRoute } from '@/modules/decision-tables/utils/route';
import { CODE_SNIPPET_BASE_ROUTE } from '@/modules/code-snippet/utils/route';

export default function useModuleRoute() {
  const moduleToRoute: Partial<Record<ModuleValues, string>> = useMemo(
    () => ({
      [Modules.Project]: PROJECTS_BASE_ROUTE,

      // Workflow product
      [Modules.WorkflowDefinition]: getAdaptedProjectsRoute(
        WORKFLOWS_BASE_ROUTE,
        DEFAULT_PROJECT_ID,
      ),
      [Modules.WorkflowLibrary]: getAdaptedProjectsRoute(
        '/automations/templates',
        DEFAULT_PROJECT_ID,
      ),
      [Modules.api_platform]: getAdaptedProjectsRoute(API_PLATFORM_BASE_ROUTE, DEFAULT_PROJECT_ID),
      [Modules.CallableInterface]: getAdaptedProjectsRoute(
        getCallableInterfacesRoute(),
        DEFAULT_PROJECT_ID,
      ),

      // Data product
      [Modules.PipelineConfig]: getAdaptedProjectsRoute(PIPELINE_BASE_ROUTE, DEFAULT_PROJECT_ID),
      [Modules.data_catalog]: DATA_CATALOG_BASE_ROUTE,
      [Modules.UnifiedEntityModel]: getModelsManagerRoute(),

      // AI
      [Modules.ai_agent]: AI_AGENTS_BASE_ROUTE,
      [Modules.Copilot]: '/copilot',
      [Modules.AIModels]: AI_MODELS_BASE_ROUTE,
      [Modules.CustomModels]: '/custom-models',

      [Modules.NoCodeInterface]: getAdaptedProjectsRoute(INTERFACE_BASE_ROUTE, DEFAULT_PROJECT_ID),

      // CDP
      [Modules.e_campaign]: getAdaptedProjectsRoute(CAMPAIGN_BASE_ROUTE, DEFAULT_PROJECT_ID),
      [Modules.SegmentDefinition]: getAdaptedProjectsRoute(SEGMENT_BASE_ROUTE, DEFAULT_PROJECT_ID),

      [Modules.EntityType]: getAdaptedProjectsRoute(getObjectsManagerRoute(), DEFAULT_PROJECT_ID),

      // Platform tools
      [Modules.Connection]: getAdaptedProjectsRoute(getConnectionsRoute(), DEFAULT_PROJECT_ID),
      [Modules.ConnectorDefinition]: getConnectorsRoute(),
      [Modules.EnvironmentVariables]: getAdaptedProjectsRoute(
        getEnvVariableRoute(),
        DEFAULT_PROJECT_ID,
      ),
      [Modules.e_streams]: getAdaptedProjectsRoute(EVENT_STREAMS_BASE_ROUTE, DEFAULT_PROJECT_ID),
      [Modules.decision_tables]: getAdaptedProjectsRoute(
        getDecisionTablesManagerRoute(),
        DEFAULT_PROJECT_ID,
      ),
      [Modules.CodeSnippet]: getAdaptedProjectsRoute(CODE_SNIPPET_BASE_ROUTE, DEFAULT_PROJECT_ID),
      [Modules.e_template]: getAdaptedProjectsRoute(TEMPLATES_BASE_ROUTE, DEFAULT_PROJECT_ID),

      // Settings
      [Modules.Settings]: '/settings',
    }),
    [],
  );

  // Keeping it function, so in future if we need to add any logic we can do it here
  const getModuleRoute = useCallback(
    (moduleId: ModuleValues) => {
      return moduleToRoute[moduleId];
    },
    [moduleToRoute],
  );

  return { getModuleRoute };
}
