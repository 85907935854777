/**
 * https://github.com/mui/material-ui/blob/master/examples/joy-ui-nextjs-ts/src/components/ThemeRegistry/ThemeRegistry.tsx
 * https://mui.com/joy-ui/react-css-baseline/#cssbaseline
 */

'use client';
import * as React from 'react';
import CssVariableProvider from '@unifyapps/style/joy/CssVariableProvider';
import { useThemeGenerator } from '../configs';
import NextAppDirEmotionCacheProvider from './EmotionCache';

/**
 * https://github.com/mui/material-ui/issues/24109#issuecomment-1059079717 for prepend: true
 * https://mui.com/material-ui/guides/interoperability/#tailwind-css
 */
const OPTIONS = { key: 'joy', prepend: true };

export default function ThemeRegistry({
  children,
  host,
}: {
  children: React.ReactNode;
  host?: string | null;
}): React.JSX.Element {
  const { theme } = useThemeGenerator(host);

  return (
    <NextAppDirEmotionCacheProvider options={OPTIONS}>
      <CssVariableProvider theme={theme}>{children}</CssVariableProvider>
    </NextAppDirEmotionCacheProvider>
  );
}
