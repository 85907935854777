import type { StyleOverrides } from '@mui/joy';
import type { ToggleButtonGroupSlot } from '@mui/joy/ToggleButtonGroup';
import type { Theme } from '@unifyapps/style/joy/theme';
import { getButtonGroupStyleOverrides } from '../ButtonGroup/style-overrides';

export const getToggleButtonGroupStyleOverrides = (): StyleOverrides<
  ToggleButtonGroupSlot,
  unknown,
  Theme
> => {
  return {
    root: getButtonGroupStyleOverrides().root,
  };
};
