import { useCallback, useContext } from 'react';
import _noop from 'lodash/noop';
import { invariant } from 'ts-invariant';
import { ActionType } from '@unifyapps/defs/types/action';
import { useDrawer } from '../../../components/Drawer/useDrawer';
import { useActionsContext } from '../ActionsProvider';
import { useInterfaceStore } from '../../stores/InterfaceStore';
import { InterfaceStoreContext } from '../../stores/InterfaceStore/InterfaceStoreProvider';
import type { PageCta } from './types';

type Props = {
  cta: PageCta;
  children: ({ onClick }: { onClick: () => void }) => JSX.Element;
};

const getDrawerSlotProps = ({ width }: { width: string }) => ({
  root: {
    className: 'isolate h-full absolute',
    style: { width },
  },
  content: {
    className: 'w-full h-full relative bg-primary',
  },
  backdrop: {
    className: 'absolute',
  },
});

function PageCtaActionProvider({ cta, children }: Props) {
  const { replace: replaceDrawer, hide: hideDrawer } = useDrawer();

  const { doAction } = useActionsContext();

  const interfaceStore = useInterfaceStore();
  const pageEl = interfaceStore.use.page.interfacePageEl();
  const interfaceRecordId = interfaceStore.use.record.id();

  const onClick = useCallback(() => {
    if (cta.target === 'drawer') {
      replaceDrawer({
        template: 'interfacePage',
        anchor: 'right',
        resizable: true,
        styleBased: Boolean(cta.width),
        slotProps: getDrawerSlotProps({ width: cta.width.custom }),
        container: pageEl,
        header: ' ', // Todo: figure out how to render page header
        componentProps: {
          pageId: cta.page,
          pageInputs: cta.pageInputs,
          interfaceId: interfaceRecordId,
        },
      });
    } else {
      // cta.target === 'page'
      hideDrawer();
      invariant(cta.page, 'Unexpected scenario in PageCtaActionProvider: pageId must be present');
      void doAction?.({
        action: {
          actionType: ActionType.NavigateToPage,
          payload: { pageId: cta.page, pageInputs: cta.pageInputs },
        },
        actionContext: {},
      });
    }
  }, [cta, doAction, hideDrawer, interfaceRecordId, pageEl, replaceDrawer]);

  return children({ onClick });
}

function PageCtaActionProviderContainer(props: Props) {
  const _useStore = useContext(InterfaceStoreContext);

  /**
   * CTA won't work if notification is sent to platform with cta of type page(unpexpected scenario).
   * Hence dont wrap PageCtaActionProvider in such a case, so that notificaiton is atleast rendered.
   * Safe check in case when cta of type page was added by any other means than the notification node config in automations.
   * It is not possible to add cta of type page from the notification node config in automations unless applicationId is selected.
   * If applicationId is selected, then it means that notification will go to a LC app and hence PageCtaActionProvider will be opened in a LC context.
   */
  if (!_useStore) {
    return props.children({ onClick: _noop });
  }

  return <PageCtaActionProvider {...props} />;
}

export { PageCtaActionProviderContainer as PageCtaActionProvider };
