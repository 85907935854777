'use client';
import { datadogRum } from '@datadog/browser-rum';
// noinspection TypeScriptUnresolvedReference
/* eslint-disable @typescript-eslint/no-non-null-assertion -- datadog specific envs */

if (
  process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID! &&
  process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN!
) {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID!,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN!,
    site: process.env.NEXT_PUBLIC_DATADOG_SITE ?? 'datadoghq.com',
    service: 'unifyapps-platform',
    env: process.env.NODE_ENV,
  });
  datadogRum.setGlobalContextProperty('product', 'unifyapps');
}

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
