'use client';

import { captureException, withScope } from '@sentry/nextjs';
import { Children, cloneElement, type ReactElement } from 'react';
import type { QueryProviderProps } from '@unifyapps/network/utils/QueryProvider';

const hasSentry = process.env.NEXT_PUBLIC_SENTRY_DSN && process.env.NODE_ENV === 'production';

export default function QueryProviderSentryWrapper({ children }: { children: React.ReactNode }) {
  if (!hasSentry) {
    return children;
  }

  return Children.map(children, (child) => {
    return cloneElement(child as ReactElement<QueryProviderProps>, {
      withScope,
      captureException,
    });
  });
}
