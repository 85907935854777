import { tabClasses } from '@mui/joy/Tab';
import type { TabOwnerState, TabSlot } from '@mui/joy/Tab';
import type { StyleOverrides, Theme } from '@unifyapps/style/joy/theme';

export const getTabStyleOverrides = (): StyleOverrides<TabSlot, TabOwnerState, Theme> => {
  return {
    root: () => {
      return [
        {
          paddingInlineStart: 0,
          paddingInlineEnd: 0,
          paddingBlockStart: 0,
          paddingBlockEnd: 0,
          '--focus-thickness': 0,
          '&::after': {
            pointerEvents: 'none',
          },
          [`&.${tabClasses.horizontal}::after`]: {
            bottom: '-1px',
          },
          '&:hover': {
            backgroundColor: 'unset',
          },
          '&:focus': {
            backgroundColor: 'unset',
          },
          '&:active': {
            backgroundColor: 'unset',
          },
          [`&.${tabClasses.selected}`]: {
            backgroundColor: 'unset',
          },
        },
      ];
    },
  };
};
