import { useTranslation } from '@unifyapps/i18n/client';
import { useMemo } from 'react';
import { Modules } from '../../consts/modules';

export default function useDefaultModuleDefs() {
  const { t } = useTranslation(['common']);

  return useMemo(
    () => ({
      [Modules.WorkflowDefinition]: {
        id: Modules.WorkflowDefinition,
        name: t('common:modules.WORKFLOW_DEFINITION.name'),
        pluralName: t('common:modules.WORKFLOW_DEFINITION.name_plural'),
      },
      [Modules.NoCodeInterface]: {
        id: Modules.NoCodeInterface,
        name: t('common:modules.e_interface.name'),
        pluralName: t('common:modules.e_interface.name_plural'),
      },
      [Modules.PipelineConfig]: {
        id: Modules.PipelineConfig,
        name: t('common:modules.DATA_PIPELINE.name'),
        pluralName: t('common:modules.DATA_PIPELINE.name_plural'),
      },
    }),
    [t],
  );
}
