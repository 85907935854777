import { useCallback } from 'react';
import { useDrawer } from '../Drawer/useDrawer';
import { useRouter } from '../../navigation';
import { PageCtaActionProvider } from '../../no-code/components/Notifications/PageCtaActionProvider';
import type { Cta, UrlCta } from './types';

function UrlCtaActionProvider({
  cta,
  children,
}: {
  cta: UrlCta;
  children: ({ onClick }: { onClick: () => void }) => JSX.Element;
}) {
  const { hide: hideDrawer } = useDrawer();

  const router = useRouter();

  const onClick = useCallback(() => {
    hideDrawer();
    if (cta.openInNewTab) {
      window.open(cta.url, '_newtab');
    } else {
      router.push(cta.url);
    }
  }, [cta.openInNewTab, cta.url, hideDrawer, router]);

  return children({ onClick });
}

function NotificationActionProvider({
  cta,
  children,
}: {
  cta: Cta | undefined;
  children: ({ onClick }: { onClick: (() => void) | undefined }) => JSX.Element;
}) {
  if (cta?.type === 'page') {
    return <PageCtaActionProvider cta={cta}>{children}</PageCtaActionProvider>;
  } else if (cta?.type === 'url') {
    return <UrlCtaActionProvider cta={cta}>{children}</UrlCtaActionProvider>;
  }

  return children({ onClick: undefined });
}

export { NotificationActionProvider };
