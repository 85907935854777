//todo remove and use from BE theme
export const enum Client {
  Walkme = 'Walkme',
  UnifyApps = 'UnifyApps',
  Hdfc = 'Hdfc',
  DpDp = 'DpDp',
  Uat = 'Uat',
  Dt = 'Dt',
  Lenskart = 'Lenskart',
  Pb = 'Pb',
  Boat = 'Boat',
  ContentStack = 'ContentStack',
  Ecom = 'Ecom',
  GreyOrange = 'GreyOrange',
  Meesho = 'Meesho',
  IronMountain = 'IronMountain',
  Project44 = 'Project44',
  Kpmg = 'Kpmg',
  Belcorp = 'Belcorp',
  Phonepe = 'Phonepe',
  Alshaya = 'Alshaya',
  SaxoBank = 'SaxoBank',
  JubilantFoods = 'JubilantFoods',
  Logikio = 'Logikio',
  Saastr = 'Saastr',
  Decathlon = 'Decathlon',
  Getomnify = 'Getomnify',
  Lumeus = 'Lumeus',
  Trustero = 'Trustero',
  Wasl = 'Wasl',
}

// add new client here
const hostToClientMap: Record<string, Client> = {
  'walkme.unifyapps.com': Client.Walkme,
  'unifyapps.walkme.com': Client.Walkme,
  'hdfc.uat.unifyapps.com': Client.Hdfc,
  'dt.uat.unifyapps.com': Client.Dt,
  'uat.ua.yo-digital.com': Client.Dt,
  'ipaas.wl0.np.aws.dot.telekom.de': Client.Dt,
  'data-privacy.qa.unifyapps.com': Client.DpDp,
  'lenskart.uat.unifyapps.com': Client.Lenskart,
  'pbqaunifyapps.policybazaar.com': Client.Pb,
  'boat.uat.unifyapps.com': Client.Boat,
  'contentstack.uat.unifyapps.com': Client.ContentStack,
  'ecom.uat.unifyapps.com': Client.Ecom,
  'greyorange.uat.unifyapps.com': Client.GreyOrange,
  'greyorange.prod.unifyapps.com': Client.GreyOrange,
  'meesho.uat.unifyapps.com': Client.Meesho,
  'ironmountain.uat.unifyapps.com': Client.IronMountain,
  'project44.uat.unifyapps.com': Client.Project44,
  'kpmg.uat.unifyapps.com': Client.Kpmg,
  'belcorp.uat.unifyapps.com': Client.Belcorp,
  'phonepe.uat.unifyapps.com': Client.Phonepe,
  'alshaya.uat.unifyapps.com': Client.Alshaya,
  'saxo-bank.uat.unifyapps.com': Client.SaxoBank,
  'jubilant-foods.uat.unifyapps.com': Client.JubilantFoods,
  'logik-io.uat.unifyapps.com': Client.Logikio,
  'saastr.uat.unifyapps.com': Client.Saastr,
  'decathlon-uat.unifyapps.com': Client.Decathlon,
  'getomnify.uat.unifyapps.com': Client.Getomnify,
  'lumeus.uat.unifyapps.com': Client.Lumeus,
  'trustero.uat.unifyapps.com': Client.Trustero,
  'wasl.uat.unifyapps.com': Client.Wasl,
};

const hostToClientEntries = Object.entries(hostToClientMap);

export function getClient(host?: string | null) {
  if (process.env.NEXT_PUBLIC_CLIENT) {
    return process.env.NEXT_PUBLIC_CLIENT as Client;
  }

  for (const [key, value] of hostToClientEntries) {
    if (host === key || host?.endsWith(key)) {
      return value;
    }
  }

  // default config for unifyapps uat
  // if (host?.endsWith('uat.unifyapps.com')) {
  //   return Client.Uat;
  // }

  // default config for unifyapps qa
  return Client.UnifyApps;
}
