import type { ModalOwnerState, ModalSlot } from '@mui/joy/Modal';
import type { StyleOverrides, Theme } from '@unifyapps/style/joy/theme';

export const getModalDialogStyleOverrides = (): StyleOverrides<
  ModalSlot,
  ModalOwnerState,
  Theme
> => {
  return {
    root: () => ({
      transform: 'revert',
      top: 'auto',
      left: 'auto',
      position: 'relative',
      margin: 'auto',
    }),
  };
};

export const getModalRootStyleOverrides = (): StyleOverrides<ModalSlot, ModalOwnerState, Theme> => {
  return {
    root: () => ({
      display: 'flex',
    }),
  };
};
