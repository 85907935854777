import { formatDuration } from 'date-fns';
import findLastIndex from 'lodash/findLastIndex';

const MILLISECONDS = 1,
  SECOND = 1000,
  MINUTE = 60 * SECOND,
  HOUR = 60 * MINUTE,
  DAY = 24 * HOUR,
  MONTH = 31.5 * DAY,
  YEAR = 365 * DAY;

const millisPreMul = [YEAR, MONTH, DAY, HOUR, MINUTE, SECOND, MILLISECONDS];
const precisionKeys = [
  'years',
  'months',
  'days',
  'hours',
  'minutes',
  'seconds',
  'milliseconds',
] as const;
const compactDurationSuffixes = ['y', 'mo', 'd', 'h', 'm', 's', 'ms'];

/**
 * Formats a time in milliseconds to a string.
 * @param ms - The time in milliseconds
 * @param precision - The precision of the time. Can be 'hours', 'minutes', 'seconds', or 'milliseconds'. Shows units up to the specified precision.
 * @returns The formatted time
 *
 * @example
 * ```tsx
 * formatTime(1005, 'hours'); // '0h'
 * formatTime(1005, 'seconds'); // '1s'
 * formatTime(1005, 'milliseconds'); // '1s 5ms'
 * formatTime(3610001, 'seconds'); // '1h 0m 10s'
 * formatTime(3500000, 'hours'); // '0h'
 * formatTime(3600000, 'hours'); // '1h'
 * ```
 */

const millisInPreviousDurations = (durationArray: number[]) =>
  durationArray.reduce((acc, _, i) => acc + durationArray[i] * millisPreMul[i], 0);

export function formatTime(
  ms: number,
  precision:
    | 'years'
    | 'months'
    | 'days'
    | 'hours'
    | 'minutes'
    | 'seconds'
    | 'milliseconds' = 'milliseconds',
  notation: 'comfortable' | 'compact' = 'compact',
  maxSignificantUnits = millisPreMul.length,
) {
  // EXAMPLE ms 269329401003 = ( 3 * MILLISECONDS  +  21 * SECOND  +  43 * MINUTE  +  5 * HOUR  +  8 * DAY  +  6 * MONTHS  +  8 * YEAR )

  /**
   * Calculate duration array for given milliseconds where
   * [index 0 is years, index 1 is months, index 2 is days, index 3 is hours, index 4 is minutes, index 5 is seconds, index 6 is milliseconds]
   */
  const durationArray = millisPreMul.reduce<number[]>((acc, _, index) => {
    const msInPreviousCalculatedDurations = index > 0 ? millisInPreviousDurations(acc) : 0;

    return [...acc, Math.floor((ms - msInPreviousCalculatedDurations) / millisPreMul[index])];
  }, []);
  // EXAMPLE ANS: duration array [8, 6, 8, 5, 43, 21, 3]

  /**
   * endIndex - index of above duration array until which duration should be displayed
   * it should be according to precision
   * minimum is to ensure that duration value of that index is not zero
   */

  const firstNonZeroIndex = durationArray.findIndex((val) => val > 0);
  const lastNonZeroIndex = findLastIndex(durationArray, (val) => val > 0);

  const lastIndexAccordingToPrecision = precisionKeys.indexOf(precision);

  const maximumSignificantUnits = Math.min(
    Math.max(maxSignificantUnits, 1),
    millisPreMul.length - 1,
  );

  const lastSignificantIndex = firstNonZeroIndex + maximumSignificantUnits - 1;

  const endIndex = Math.min(lastIndexAccordingToPrecision, lastSignificantIndex, lastNonZeroIndex);
  // EXAMPLE ANS: 6

  /**
   * startIndex is highest duration unit which has non zero value for given milliseconds
   */
  const startIndex = durationArray.findIndex((val) => val > 0);
  // EXAMPLE ANS: 0

  /**
   * When we want to display duration as 8 years 6 months 8 days 5 hours 43 minutes 21 seconds 3 milliseconds
   */
  if (notation === 'comfortable') {
    /**
     * key value map of above duration array
     */
    const durationObj = precisionKeys.reduce(
      (acc, key, index) => {
        if (index < startIndex || index > endIndex) return acc;

        acc[key] = durationArray[index];

        return acc;
      },
      {} as Partial<{ [key in (typeof precisionKeys)[number]]: number }>,
    );
    // EXAMPLE ANS: { milliseconds: 3, seconds: 21, minutes: 43, hours: 5, days: 8, months: 6, years: 8 }

    //milliseconds not handled by date-fns
    const appendableMilliSecondsString = durationObj.milliseconds
      ? ` ${durationObj.milliseconds} milliseconds`
      : '';

    return `${formatDuration(durationObj)}${appendableMilliSecondsString}`;
    // EXAMPLE RETURN: 8 years 6 months 8 days 5 hours 43 minutes 21 seconds 3 milliseconds
  }

  /**
   * When we want to display duration as 8y 6m 8d 5h 43mi 21s 3ms
   * create a string as above from duration array
   */
  return compactDurationSuffixes.reduce((acc, compactDurationSuffix, index) => {
    if (index < startIndex || index > endIndex) return acc;

    return `${acc} ${durationArray[index]}${compactDurationSuffix}`;
  }, '');
  // EXAMPLE RETURN: 8y 6m 8d 5h 43mi 21s 3ms
}
