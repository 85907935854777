export const BASE_ROUTE = '/event-streams';
const ALL_EVENT_STREAMS = '/event-streams/all';

export const getEventStreamsManagerRoute = () => BASE_ROUTE;

export const getEventStreamsAllRoute = () => `${BASE_ROUTE}/all`;
export const getEventStreamsDashboardRoute = () => `${BASE_ROUTE}/insights`;

export const getCreateEventStreamRoute = () => `${ALL_EVENT_STREAMS}/create`;
export const getRenameEventStreamRoute = (eventStreamId: string) =>
  `${ALL_EVENT_STREAMS}/edit/${eventStreamId}`;

export const getEditEventStreamRoute = (eventStreamId: string, tab = 'schema') =>
  `${BASE_ROUTE}/${eventStreamId}/${tab}`;

export const getShareEventStreamRoute = (eventStreamId: string) =>
  `${ALL_EVENT_STREAMS}/share/${eventStreamId}`;

export const getEventStreamDetailsRoute = (eventStreamId: string, tab = 'schema') =>
  `${BASE_ROUTE}/${eventStreamId}/${tab}`;

export const getEventStreamLogDetailsRoute = (eventStreamId: string, messageId: string) =>
  `${BASE_ROUTE}/${eventStreamId}/logs/${messageId}`;
