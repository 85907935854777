import { ConnectorCreatorTabs } from '../components/ConnectorDetailsTabs/types';
import { ConnectorActionDetailsTabsEnum } from '../views/ConnectorActionDetails/types';
import { ConnectorAuthenticationDetailsTabsEnum } from '../views/ConnectorAuthenticationDetails/types';

export const getConnectorsRoute = () => '/connectors';

export const getCreateConnectorRoute = () => '/connectors/create';

export const getShareConnectorRoute = (connectorId: string) => `/connectors/share/${connectorId}`;

export const getConnectorDetailsRoute = (connectorId: string) => `/connectors/${connectorId}`;

export const getConnectorDetailsTabRoute = (
  connectorId: string,
  tab = ConnectorCreatorTabs.Authentications,
) => `/connectors/${connectorId}/${tab}`;

export const getConnectorsAuthenticationCreatorRoute = (connectorId: string) =>
  `/connectors/${connectorId}/authentications/create`;

export const getConnectorsAuthenticationRoute = (connectorId: string) =>
  `/connectors/${connectorId}/authentications`;

export const getConnectorAuthenticationDetailsRoute = (
  connectorId: string,
  authenticationId: string,
  tabId = ConnectorAuthenticationDetailsTabsEnum.Inputs,
) => `/connectors/${connectorId}/authentications/${authenticationId}/${tabId}`;

// connector action routes

export const getConnectorsActionCreateRoute = (connectorId: string) =>
  `/connectors/${connectorId}/actions/create`;

export const getConnectorsActionsRoute = (connectorId: string) =>
  `/connectors/${connectorId}/actions`;

export const getConnectorActionDetailsRoute = (
  connectorId: string,
  actionId: string,
  tabId = ConnectorActionDetailsTabsEnum.Inputs,
) => `/connectors/${connectorId}/actions/${actionId}/${tabId}`;

// connector trigger routes

export const getConnectorsTriggerCreateRoute = (connectorId: string) =>
  `/connectors/${connectorId}/triggers/create`;

export const getConnectorsTriggersRoute = (connectorId: string) =>
  `/connectors/${connectorId}/triggers`;

export const getConnectorTriggerDetailsRoute = (
  connectorId: string,
  triggerId: string,
  tabId = ConnectorActionDetailsTabsEnum.Inputs,
) => `/connectors/${connectorId}/triggers/${triggerId}/${tabId}`;
