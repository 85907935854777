'use client';
import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

const ProductTour = dynamic(() => import(/* webpackChunkName: "product-tour" */ './ProductTour'));

function isProductTourEnabled(): boolean {
  const automation = typeof navigator !== 'undefined' && navigator.webdriver;

  return !automation;
}

function ProductTourContainer() {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  useEffect(() => {
    setIsEnabled(isProductTourEnabled());
  }, []);

  if (!isEnabled) {
    return null;
  }

  return <ProductTour />;
}

export default ProductTourContainer;
