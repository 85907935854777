import type { StyleOverrides, Theme } from '@mui/joy';
import type { ChipSlot, ChipOwnerState } from '@mui/joy/Chip/ChipProps';

export const getChipStyleOverrides = (): StyleOverrides<ChipSlot, ChipOwnerState, Theme> => {
  return {
    root: () => [{}],
    action: () => [
      {
        backgroundColor: 'unset',
        ':hover': {
          backgroundColor: 'unset',
        },
        border: 'none',
      },
    ],
  };
};
