import _castArray from 'lodash/castArray';
import _intersection from 'lodash/intersection';
import type { EntityTypeVsAssetClass } from '../../consts/permissions';
import { Permission } from '../../consts/permissions';
import type { GlobalAccessControlContextType } from '../context/GlobalAccessControl';

export const hasPermissionAccess = (
  permissions: Permission[] | undefined,
  permissionToAccess: Permission | Permission[],
) => {
  return Boolean(
    permissions?.includes(Permission.ALL) ||
      _intersection(permissions, _castArray(permissionToAccess)).length > 0,
  );
};

export const hasAccess = (
  permissions: GlobalAccessControlContextType,
  entityType: EntityTypeVsAssetClass,
  permissionToAccess: Permission | Permission[],
) => {
  return hasPermissionAccess(permissions[entityType], permissionToAccess);
};
