/**
 * Generated by orval 🍺
 * Do not edit manually.
 * api API
 * OpenAPI spec version: 1.0
 */

export type Op = (typeof Op)[keyof typeof Op];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Op = {
  AND: 'AND',
  OR: 'OR',
  NOT: 'NOT',
  EQUAL: 'EQUAL',
  NOT_EQUAL: 'NOT_EQUAL',
  IN: 'IN',
  NOT_IN: 'NOT_IN',
  LT: 'LT',
  LTE: 'LTE',
  GT: 'GT',
  GTE: 'GTE',
  BETWEEN: 'BETWEEN',
  REGEX: 'REGEX',
  NOT_REGEX: 'NOT_REGEX',
  STARTS_WITH: 'STARTS_WITH',
  ENDS_WITH: 'ENDS_WITH',
  CONTAINS: 'CONTAINS',
  NOT_CONTAINS: 'NOT_CONTAINS',
  IREGEX: 'IREGEX',
  NOT_IREGEX: 'NOT_IREGEX',
  ICONTAINS: 'ICONTAINS',
  NOT_ICONTAINS: 'NOT_ICONTAINS',
  EXISTS: 'EXISTS',
  MISSING: 'MISSING',
  CONTEXTUAL: 'CONTEXTUAL',
} as const;
