/**
 * https://github.com/mui/material-ui/blob/master/examples/joy-ui-nextjs-ts/src/components/ThemeRegistry/theme.ts
 * https://github.dev/mui/material-ui/blob/d37b6cc7e6c7214760b9729c435e028fcb8fe078/packages/mui-joy/src/styles/extendTheme.ts
 */

import getTheme from '@unifyapps/ui/styles/theme';
import { inter, sourceCodePro } from '@/utils/fonts';

const dtTheme = getTheme({
  fontFamily: {
    body: inter.style.fontFamily,
    display: inter.style.fontFamily,
    text: inter.style.fontFamily,
    code: sourceCodePro.style.fontFamily,
  },
});

export default dtTheme;
