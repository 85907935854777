import { Modules } from '@unifyapps/carbon/consts/modules';

export function showSettingsIcon(permissionsObject: Record<string, boolean>) {
  const showIcon =
    permissionsObject[Modules.BusinessHolidays] ||
    permissionsObject[Modules.BusinessHours] ||
    permissionsObject[Modules.Changeset] ||
    permissionsObject[Modules.ChangesetRequest] ||
    permissionsObject[Modules.CustomerEnvironment] ||
    permissionsObject[Modules.Roles] ||
    permissionsObject[Modules.Teams] ||
    permissionsObject[Modules.User];

  return showIcon;
}
