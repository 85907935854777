/**
 * Generated by orval 🍺
 * Do not edit manually.
 * api API
 * OpenAPI spec version: 1.0
 */
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import type {
  InfiniteData,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { HitsNotification } from '../models/hitsNotification';
import type { MarkAsOpenParams } from '../models/markAsOpenParams';
import type { MarkAsReadParams } from '../models/markAsReadParams';
import type { Query } from '../models/query';
import { executeFetch } from '../../utils/fetch/fetch';
import type { ErrorType } from '../../utils/fetch/fetch';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const deleteAllNotifications = (options?: SecondParameter<typeof executeFetch>) => {
  return executeFetch<void>({ url: `/api/notification/deleteAll`, method: 'POST' }, options);
};

export const getDeleteAllNotificationsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAllNotifications>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAllNotifications>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAllNotifications>>,
    void
  > = () => {
    return deleteAllNotifications(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAllNotificationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAllNotifications>>
>;

export type DeleteAllNotificationsMutationError = ErrorType<unknown>;

export const useDeleteAllNotifications = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAllNotifications>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteAllNotifications>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getDeleteAllNotificationsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const markAsOpen = (
  params?: MarkAsOpenParams,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<boolean>(
    { url: `/api/notification/markAsOpen`, method: 'POST', params },
    options,
  );
};

export const getMarkAsOpenMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof markAsOpen>>,
    TError,
    { params?: MarkAsOpenParams },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof markAsOpen>>,
  TError,
  { params?: MarkAsOpenParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof markAsOpen>>,
    { params?: MarkAsOpenParams }
  > = (props) => {
    const { params } = props ?? {};

    return markAsOpen(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MarkAsOpenMutationResult = NonNullable<Awaited<ReturnType<typeof markAsOpen>>>;

export type MarkAsOpenMutationError = ErrorType<unknown>;

export const useMarkAsOpen = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof markAsOpen>>,
    TError,
    { params?: MarkAsOpenParams },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof markAsOpen>>,
  TError,
  { params?: MarkAsOpenParams },
  TContext
> => {
  const mutationOptions = getMarkAsOpenMutationOptions(options);

  return useMutation(mutationOptions);
};
export const markAsRead = (
  params?: MarkAsReadParams,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<void>(
    { url: `/api/notification/markAsRead`, method: 'POST', params },
    options,
  );
};

export const getMarkAsReadMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof markAsRead>>,
    TError,
    { params?: MarkAsReadParams },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof markAsRead>>,
  TError,
  { params?: MarkAsReadParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof markAsRead>>,
    { params?: MarkAsReadParams }
  > = (props) => {
    const { params } = props ?? {};

    return markAsRead(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MarkAsReadMutationResult = NonNullable<Awaited<ReturnType<typeof markAsRead>>>;

export type MarkAsReadMutationError = ErrorType<unknown>;

export const useMarkAsRead = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof markAsRead>>,
    TError,
    { params?: MarkAsReadParams },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof markAsRead>>,
  TError,
  { params?: MarkAsReadParams },
  TContext
> => {
  const mutationOptions = getMarkAsReadMutationOptions(options);

  return useMutation(mutationOptions);
};
export const findNotifications = (query: Query, options?: SecondParameter<typeof executeFetch>) => {
  return executeFetch<HitsNotification>(
    {
      url: `/api/notification/search`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: query,
    },
    options,
  );
};

export const getFindNotificationsQueryKey = (query: Query) => {
  return [`/api/notification/search`, query] as const;
};

export const getFindNotificationsInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof findNotifications>>>,
  TError = ErrorType<unknown>,
>(
  query: Query,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof findNotifications>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFindNotificationsQueryKey(query);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof findNotifications>>> = () =>
    findNotifications(query, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof findNotifications>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FindNotificationsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof findNotifications>>
>;
export type FindNotificationsInfiniteQueryError = ErrorType<unknown>;

export const useFindNotificationsInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof findNotifications>>>,
  TError = ErrorType<unknown>,
>(
  query: Query,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof findNotifications>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFindNotificationsInfiniteQueryOptions(query, options);

  const _query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  _query.queryKey = queryOptions.queryKey;

  return _query;
};

export const prefetchFindNotificationsInfinite = async <
  TData = Awaited<ReturnType<typeof findNotifications>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  query: Query,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof findNotifications>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getFindNotificationsInfiniteQueryOptions(query, options);

  await queryClient.prefetchInfiniteQuery(queryOptions);

  return queryClient;
};

export const getFindNotificationsQueryOptions = <
  TData = Awaited<ReturnType<typeof findNotifications>>,
  TError = ErrorType<unknown>,
>(
  query: Query,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof findNotifications>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFindNotificationsQueryKey(query);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof findNotifications>>> = () =>
    findNotifications(query, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof findNotifications>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FindNotificationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof findNotifications>>
>;
export type FindNotificationsQueryError = ErrorType<unknown>;

export const useFindNotifications = <
  TData = Awaited<ReturnType<typeof findNotifications>>,
  TError = ErrorType<unknown>,
>(
  query: Query,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof findNotifications>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFindNotificationsQueryOptions(query, options);

  const _query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  _query.queryKey = queryOptions.queryKey;

  return _query;
};

export const prefetchFindNotifications = async <
  TData = Awaited<ReturnType<typeof findNotifications>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  query: Query,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof findNotifications>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getFindNotificationsQueryOptions(query, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getUnopenedNotificationsCount = (
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<number>(
    { url: `/api/notification/unopenedCount`, method: 'GET', signal },
    options,
  );
};

export const getGetUnopenedNotificationsCountQueryKey = () => {
  return [`/api/notification/unopenedCount`] as const;
};

export const getGetUnopenedNotificationsCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getUnopenedNotificationsCount>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUnopenedNotificationsCount>>, TError, TData>
  >;
  request?: SecondParameter<typeof executeFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUnopenedNotificationsCountQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUnopenedNotificationsCount>>> = ({
    signal,
  }) => getUnopenedNotificationsCount(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUnopenedNotificationsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUnopenedNotificationsCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUnopenedNotificationsCount>>
>;
export type GetUnopenedNotificationsCountQueryError = ErrorType<unknown>;

export const useGetUnopenedNotificationsCount = <
  TData = Awaited<ReturnType<typeof getUnopenedNotificationsCount>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUnopenedNotificationsCount>>, TError, TData>
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUnopenedNotificationsCountQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetUnopenedNotificationsCount = async <
  TData = Awaited<ReturnType<typeof getUnopenedNotificationsCount>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUnopenedNotificationsCount>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetUnopenedNotificationsCountQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};
