'use client';

import React, { useMemo } from 'react';
import userStore from '@unifyapps/carbon/auth/userStore';
import { useUnopenedNotifications } from '@unifyapps/carbon/components/Notifications/hooks/useUnopenedNotifications';
import useFallbackSidenavItems from '@/modules/sidenav/hooks/useFallbackSidenavItems';
import useEntitySidenavItems from '@/modules/sidenav/hooks/useEntitySidenavItems';
import { AppSidenavContext } from './AppSidenavContext';

export function AppSidenavProvider({ children }) {
  const currentUserDetails = userStore.use.currentUserDetails();

  const user = currentUserDetails?.user;

  const loggedInUserEmail = user?.email || user?.username;
  const loggedInUserName = user?.name;
  const customerName = currentUserDetails?.customer?.name;
  const assumeContextAllowed = currentUserDetails?.assumeContextAllowed;
  const { unopenedNotificationsCount } = useUnopenedNotifications({ loggedInUserId: user?.id });

  const { primaryNavItems: fallbackPrimaryNavItems, secondaryNavItems: fallbackSecondaryNavItems } =
    useFallbackSidenavItems();

  const { primaryNavItems, secondaryNavItems, isLoading, error } = useEntitySidenavItems();

  const hasEntitySidenavItems = primaryNavItems.length || secondaryNavItems.length;

  // isLoading will be false since it's prefetched. If there is an error, we will fallback to the default nav items till the error is resolved
  const primary = hasEntitySidenavItems ? primaryNavItems : fallbackPrimaryNavItems;
  const secondary = hasEntitySidenavItems ? secondaryNavItems : fallbackSecondaryNavItems;

  const providerValue = useMemo(
    () => ({
      primary,
      secondary,
      isLoading,
      error,
      assumeContextAllowed,
      customerName,
      loggedInUserEmail,
      loggedInUserName,
      unopenedNotificationsCount,
    }),
    [
      primary,
      secondary,
      isLoading,
      error,
      assumeContextAllowed,
      customerName,
      loggedInUserEmail,
      loggedInUserName,
      unopenedNotificationsCount,
    ],
  );

  return <AppSidenavContext.Provider value={providerValue}>{children}</AppSidenavContext.Provider>;
}
