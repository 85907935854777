// eslint-disable-next-line @typescript-eslint/ban-ts-comment -- joy ui will generate variables for these even if they are not defined in type
// @ts-nocheck
/**
 * This is the base theme object which is extended with palette colors and used to create css variables
 * How to use this? Use this object to create your own theme object with added palette colors and other overrides and pass it to CSSVars to create css variables
 */

import type { CssVarsThemeOptions } from '@mui/joy/styles/extendTheme'; // import { extraPalette, unifyThemePalette } from '../primitives';
// import { unifyThemePalette } from '../primitives';

export { extendTheme, type StyleOverrides, type Theme } from '@mui/joy/styles';

export const themeOptions: CssVarsThemeOptions = {
  // radius: {
  //   xs: '100vmax',
  //   sm: '100vmax',
  //   md: '100vmax',
  //   lg: '100vmax',
  //   xl: '100vmax',
  // },
  // colorSchemes: {
  //   light: {
  //     palette: {
  //       common: {
  //         black: '#ff00ff',
  //         white: '#ff00ff',
  //       },
  //       primary: {
  //         '50': '#ff00ff',
  //         '100': '#ff00ff',
  //         '200': '#ff00ff',
  //         '300': '#ff00ff',
  //         '400': '#ff00ff',
  //         '500': '#ff00ff',
  //         '600': '#ff00ff',
  //         '700': '#ff00ff',
  //         '800': '#ff00ff',
  //         '900': '#ff00ff',
  //       },
  //       neutral: {
  //         '50': '#ff00ff',
  //         '100': '#ff00ff',
  //         '200': '#ff00ff',
  //         '300': '#ff00ff',
  //         '400': '#ff00ff',
  //         '500': '#ff00ff',
  //         '600': '#ff00ff',
  //         '700': '#ff00ff',
  //         '800': '#ff00ff',
  //         '900': '#ff00ff',
  //       },
  //       danger: {
  //         '50': '#ff00ff',
  //         '100': '#ff00ff',
  //         '200': '#ff00ff',
  //         '300': '#ff00ff',
  //         '400': '#ff00ff',
  //         '500': '#ff00ff',
  //         '600': '#ff00ff',
  //         '700': '#ff00ff',
  //         '800': '#ff00ff',
  //         '900': '#ff00ff',
  //       },
  //       warning: {
  //         '50': '#ff00ff',
  //         '100': '#ff00ff',
  //         '200': '#ff00ff',
  //         '300': '#ff00ff',
  //         '400': '#ff00ff',
  //         '500': '#ff00ff',
  //         '600': '#ff00ff',
  //         '700': '#ff00ff',
  //         '800': '#ff00ff',
  //         '900': '#ff00ff',
  //       },
  //       success: {
  //         '50': '#ff00ff',
  //         '100': '#ff00ff',
  //         '200': '#ff00ff',
  //         '300': '#ff00ff',
  //         '400': '#ff00ff',
  //         '500': '#ff00ff',
  //         '600': '#ff00ff',
  //         '700': '#ff00ff',
  //         '800': '#ff00ff',
  //         '900': '#ff00ff',
  //       },
  //     },
  //   },
  // },
  // fontSize: {
  //   'display-2xl': '10rem',
  //   'display-xl': '10rem',
  //   'display-lg': '10rem',
  //   'display-md': '10rem',
  //   'display-sm': '10rem',
  //   'display-xs': '10rem',
  //   'text-xl': '10rem',
  //   'text-lg': '10rem',
  //   'text-md': '10rem',
  //   'text-sm': '10rem',
  //   'text-xs': '10rem',
  //   'text-xxs': '10rem',
  // },
  // lineHeight: {
  //   'display-2xl': '0.25rem',
  //   'display-xl': '0.25rem',
  //   'display-lg': '0.25rem',
  //   'display-md': '0.25rem',
  //   'display-sm': '0.25rem',
  //   'display-xs': '0.25rem',
  //   'text-xl': '0.25rem',
  //   'text-lg': '0.25rem',
  //   'text-md': '0.25rem',
  //   'text-sm': '0.25rem',
  //   'text-xs': '0.25rem',
  //   'text-xxs': '0.25rem',
  // },
  // letterSpacing: {
  //   'display-2xl': '-0.5rem',
  //   'display-xl': '-0.5rem',
  //   'display-lg': '-0.5rem',
  //   'display-md': '-0.5rem',
  //   'display-sm': '-0.5rem',
  //   'display-xs': '-0.5rem',
  //   'text-xl': '-0.5rem',
  //   'text-lg': '-0.5rem',
  //   'text-md': '-0.5rem',
  //   'text-sm': '-0.5rem',
  //   'text-xs': '-0.5rem',
  //   'text-xxs': '-0.5rem',
  // },
};
