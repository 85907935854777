import(/* webpackMode: "eager", webpackExports: ["default"] */ "/runner/_work/unifyapps/unifyapps/apps/platform/src/components/EmbeddedSDK.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/runner/_work/unifyapps/unifyapps/apps/platform/src/components/external/ProductTour/index.tsx");
;
import(/* webpackMode: "eager" */ "/runner/_work/unifyapps/unifyapps/apps/platform/src/components/rjsf/widgets/VariableInputWidget/variableInputWidget.css");
;
import(/* webpackMode: "eager" */ "/runner/_work/unifyapps/unifyapps/apps/platform/src/components/InputDataPanel/InputPanelExtension/VariableTagNodeView.css");
;
import(/* webpackMode: "eager", webpackExports: ["MockInitialization"] */ "/runner/_work/unifyapps/unifyapps/apps/platform/src/mocks/MockInitialization.tsx");
;
import(/* webpackMode: "eager" */ "/runner/_work/unifyapps/unifyapps/apps/platform/src/modules/interfaces/views/InterfaceBuilder/widgets/InteractionsWidget/TreeStructure/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalUpdatesProvider"] */ "/runner/_work/unifyapps/unifyapps/apps/platform/src/modules/live/providers/GlobalUpdatesProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppSidenavProvider"] */ "/runner/_work/unifyapps/unifyapps/apps/platform/src/modules/sidenav/context/AppSidenavProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/runner/_work/unifyapps/unifyapps/apps/platform/src/providers/QueryProviderSentryWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/runner/_work/unifyapps/unifyapps/apps/platform/src/utils/theme/ThemeRegistry/ThemeRegistry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/runner/_work/unifyapps/unifyapps/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/runner/_work/unifyapps/unifyapps/node_modules/react-day-picker/dist/style.css");
;
import(/* webpackMode: "eager" */ "/runner/_work/unifyapps/unifyapps/node_modules/next/font/google/target.css?{\"path\":\"src/utils/fonts/index.ts\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"],\"adjustFontFallback\":false,\"fallback\":[\"var(--fontFamily-fallback)\"],\"display\":\"swap\",\"preload\":false}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/runner/_work/unifyapps/unifyapps/node_modules/next/font/google/target.css?{\"path\":\"src/utils/fonts/index.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"adjustFontFallback\":false,\"fallback\":[\"var(--fontFamily-fallback)\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/runner/_work/unifyapps/unifyapps/node_modules/next/font/google/target.css?{\"path\":\"src/utils/fonts/index.ts\",\"import\":\"Source_Code_Pro\",\"arguments\":[{\"subsets\":[\"latin\"],\"adjustFontFallback\":false,\"fallback\":[\"ui-monospace\",\"SFMono-Regular\",\"Menlo\",\"Monaco\",\"Consolas\",\"Liberation Mono\",\"Courier New\",\"monospace\"],\"display\":\"swap\",\"preload\":false}],\"variableName\":\"sourceCodePro\"}");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalAccessControlProvider"] */ "/runner/_work/unifyapps/unifyapps/packages/carbon/src/access-control/context/GlobalAccessControl.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/runner/_work/unifyapps/unifyapps/packages/carbon/src/auth/components/AuthProvider.tsx");
;
import(/* webpackMode: "eager" */ "/runner/_work/unifyapps/unifyapps/packages/carbon/src/components/Drawer/drawerContext.ts");
;
import(/* webpackMode: "eager" */ "/runner/_work/unifyapps/unifyapps/packages/carbon/src/components/Drawer/DrawerProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotificationsSnackbarProvider"] */ "/runner/_work/unifyapps/unifyapps/packages/carbon/src/components/Notifications/NotificationsSnackbarProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DebugContextProvider"] */ "/runner/_work/unifyapps/unifyapps/packages/carbon/src/context/DebugContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavBreadcrumbsContextProvider"] */ "/runner/_work/unifyapps/unifyapps/packages/carbon/src/navigation/breadcrumbs/context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/runner/_work/unifyapps/unifyapps/packages/carbon/src/no-code/components/NextInterfaceRouterProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModuleDefsContextProvider"] */ "/runner/_work/unifyapps/unifyapps/packages/carbon/src/providers/moduleDefs/ModuleDefsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/runner/_work/unifyapps/unifyapps/packages/carbon/src/utils/error-reporting/datadog.ts");
;
import(/* webpackMode: "eager", webpackExports: ["DeviceContextProvider"] */ "/runner/_work/unifyapps/unifyapps/packages/hooks/src/useScreen/context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageChangeProvider"] */ "/runner/_work/unifyapps/unifyapps/packages/i18n/src/client.ts");
;
import(/* webpackMode: "eager", webpackExports: ["MqttProvider"] */ "/runner/_work/unifyapps/unifyapps/packages/network/src/mqttClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryProvider"] */ "/runner/_work/unifyapps/unifyapps/packages/network/src/utils/QueryProvider.tsx");
;
import(/* webpackMode: "eager" */ "/runner/_work/unifyapps/unifyapps/packages/style/src/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["SnackbarContext"] */ "/runner/_work/unifyapps/unifyapps/packages/ui/src/components/Snackbar/context.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Snackbar"] */ "/runner/_work/unifyapps/unifyapps/packages/ui/src/components/Snackbar/Snackbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/runner/_work/unifyapps/unifyapps/packages/ui/src/components/Snackbar/SnackbarProvider.tsx");
