import type { Application } from '@unifyapps/network/generated/models/application';
import type { Entity } from '@unifyapps/network/generated/models/entity';

export enum AssetTabs {
  Overview = 'overview',
  Fields = 'fields',
  Tables = 'tables',
  Schema = 'schema',
  Databases = 'databases',
  Lineage = 'lineage',
  Quality = 'quality',
}

export enum AssetType {
  Table = 'table',
  Schema = 'schema',
  Database = 'database',
  Field = 'field',
}

export interface AssetCardDataProps {
  asset: AssetsData;
  selected?: boolean;
}

export type AssetAggregationPropertiesType = {
  name: string;
  assetType: string;
  sourceDetails: {
    sourceApp: string;
    sourceConnectionId: string;
    sourceAssetType: string;
    sourceAssetId: string;
  };
  parentAssetDetails?: {
    parentAssetType?: string;
    parentAssetId?: string;
  };
};

export type AssetAggregationType = {
  id: string;
  name: string;
  assetType: string;
  sourceApp: string;
  sourceConnectionId: string;
  sourceAssetType: string;
  sourceAssetId: string;
  sourceType: string;
  sourceName: string;
  parentAssetId?: string;
  parentAssetType?: string;
};

export type AssetLookupType = {
  name: string;
};

// -> AssetData
export type AssetsData = AssetAggregationType & {
  application?: Application;
  entity?: Entity;
};

export type DataCatalogAssetType = {
  name: string;
  assetType: string;
  classifications: string;
  glossary: string;
  parentAssetDetails?: {
    parentAssetId: string;
    parentAssetType: string;
  };
  sourceDetails?: {
    sourceAssetId: string;
    sourceAssetType: string;
    sourceId: string;
    sourceType: string;
    sourceName: string;
  };
};
