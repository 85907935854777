import { keyBy } from 'lodash';
import { useFetchRolesById } from '../user/hooks/useFetchEntitiesById';

export const useGetUserRoleNames = (roleIds: string[] | undefined) => {
  const userRoles = new Set(roleIds);

  const { roles, isLoading } = useFetchRolesById(userRoles);
  const userRolesKeyBy = keyBy(roles, 'id');

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- userRolesKeyBy is empty when roles is loading
  const userRolesName = Array.from(userRoles).map((roleId) => userRolesKeyBy[roleId]?.name);

  return { userRolesName, isRolesLoading: isLoading };
};
