'use client';

import React, { createContext, useContext, useMemo } from 'react';
import { useFetchUserEntityPermissions } from '@unifyapps/network/generated/permissions-ap-i/permissions-ap-i';
import type { EntityTypeVsAssetClass, Permission } from '../../consts/permissions';

export type GlobalAccessControlContextType = Partial<{
  [entityType in EntityTypeVsAssetClass]: Permission[];
}>;

const GlobalAccessControlContext = createContext<GlobalAccessControlContextType | null>(null);

export const useGlobalAccessControl = () => {
  const store = useContext(GlobalAccessControlContext);
  if (!store) {
    throw new Error('Missing AccessControlProvider');
  }
  return store;
};

export function GlobalAccessControlProvider({ children }: { children: React.ReactNode }) {
  const { data, isLoading } = useFetchUserEntityPermissions<{
    objects?: { entityType: EntityTypeVsAssetClass; permissions: Permission[] }[];
  }>({ query: { staleTime: Infinity } });

  const permissions = useMemo(() => {
    if (!data?.objects) return {};

    return data.objects.reduce<GlobalAccessControlContextType>(
      (acc, { entityType, permissions: entityPermissions }) => ({
        ...acc,
        [entityType]: entityPermissions,
      }),
      {},
    );
  }, [data?.objects]);

  if (isLoading) return null;

  return (
    <GlobalAccessControlContext.Provider value={permissions}>
      {children}
    </GlobalAccessControlContext.Provider>
  );
}
