'use client';
import React, { useMemo } from 'react';
import { useRouter, useParams, useSearchParams, usePathname } from '../../navigation';
import { InterfaceRouterProvider } from '../hooks/useRouter';

const useNextRouter = () => {
  const pathParams = useParams();
  const nextRouter = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();

  return useMemo(() => {
    return {
      push: (url: string) => nextRouter.push(url),
      replace: (url: string) => nextRouter.replace(url),
      back: () => nextRouter.back(),
      pathParams,
      searchParams,
      pathname,
    };
  }, [nextRouter, pathname, pathParams, searchParams]);
};

function NextInterfaceRouterProvider(props: { children: React.ReactNode }) {
  const router = useNextRouter();
  return <InterfaceRouterProvider router={router}>{props.children}</InterfaceRouterProvider>;
}

export default NextInterfaceRouterProvider;
