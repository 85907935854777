import type { TabListOwnerState, TabListSlot } from '@mui/joy/TabList';
import type { StyleOverrides, Theme } from '@unifyapps/style/joy/theme';

export const getTabListStyleOverrides = (): StyleOverrides<
  TabListSlot,
  TabListOwnerState,
  Theme
> => {
  return {
    root: () => {
      return [
        {
          backgroundColor: 'unset',
          '&:hover': {
            backgroundColor: 'unset',
          },
          // Joy UI uses box shadow for the divider, box shadow is affected orientation and placement props
          // easier to use variable than to override the box shadow with custom border
          '--palette-divider': 'var(--border-secondary)',
        },
      ];
    },
  };
};
