import { useCallback } from 'react';
import _once from 'lodash/once';
import { useRouter } from '@unifyapps/carbon/navigation';

function inIframeFn() {
  try {
    return window.self !== window.top;
  } catch (e) {
    /**
     * iframe is less permissive so some properties can be inaccessible like localStorage.
       window.top can throw error in iframe depending on user setting
     */
    return true;
  }
}

export const inIframe = _once(inIframeFn);

export default function useLink() {
  const router = useRouter();
  const openLink = useCallback(
    (url?: string) => {
      if (!url) {
        return;
      }

      if (inIframe()) {
        return router.push(url);
      }

      return window.open(url, '_blank', 'noreferrer noopener');
    },
    [router],
  );
  return { openLink };
}
