'use client';

import { useMemo } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- needed here
import { useRouter as useNextRouter, useParams } from 'next/navigation';
import type {
  AppRouterInstance,
  NavigateOptions,
} from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { getAdaptedProjectSlug, getAdaptedProjectsRoute } from './project/utils';

// Extend NavigateOptions to include projectId
interface ExtendedNavigateOptions extends NavigateOptions {
  pId?: number;
}

type NavigateWithProjectOptions = (url: string, options?: ExtendedNavigateOptions) => void;

const useRouter: () => Omit<AppRouterInstance, 'push' | 'replace'> & {
  push: NavigateWithProjectOptions;
  replace: NavigateWithProjectOptions;
} = () => {
  const router = useNextRouter();
  const { projectSlug } = useParams<{ projectSlug?: string }>();

  return useMemo(() => {
    return {
      ...router,
      push: (url, options) => {
        const adaptedSlug = getAdaptedProjectSlug(projectSlug, options?.pId);
        return router.push(getAdaptedProjectsRoute(url, adaptedSlug), options);
      },
      replace: (url, options) => {
        const adaptedSlug = getAdaptedProjectSlug(projectSlug, options?.pId);
        return router.replace(getAdaptedProjectsRoute(url, adaptedSlug), options);
      },
    };
  }, [projectSlug, router]);
};

export default useRouter;
