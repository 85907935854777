import type { ModuleConfig } from '@unifyapps/network/generated/models/moduleConfig';

export function getDisplayName(
  moduleId: string,
  defaultDisplayName: string,
  modulesMap: Record<string, Pick<ModuleConfig, 'displayName'>> = {},
): string {
  if (moduleId in modulesMap && modulesMap[moduleId].displayName) {
    return modulesMap[moduleId].displayName;
  }
  return defaultDisplayName;
}
