import type { StyleOverrides } from '@mui/joy';
import type { DividerSlot } from '@mui/joy/Divider';
import type { Theme } from '@unifyapps/style/joy/theme';

export const getDividerStyleOverrides = (): StyleOverrides<DividerSlot, unknown, Theme> => {
  return {
    root: () => {
      return [
        {
          minWidth: '1px',
          minHeight: '1px',
        },
      ];
    },
  };
};
