/**
 * Generated by orval 🍺
 * Do not edit manually.
 * api API
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryClient,
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { CurrentUserDetails } from '../models/currentUserDetails';
import type { GetApiUserContextAssumeParams } from '../models/getApiUserContextAssumeParams';
import { executeFetch } from '../../utils/fetch/fetch';
import type { ErrorType } from '../../utils/fetch/fetch';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getApiUserContext = (
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<CurrentUserDetails>(
    { url: `/api/user-context`, method: 'GET', signal },
    options,
  );
};

export const getGetApiUserContextQueryKey = () => {
  return [`/api/user-context`] as const;
};

export const getGetApiUserContextQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiUserContext>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUserContext>>, TError, TData>>;
  request?: SecondParameter<typeof executeFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiUserContextQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUserContext>>> = ({ signal }) =>
    getApiUserContext(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiUserContext>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiUserContextQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiUserContext>>
>;
export type GetApiUserContextQueryError = ErrorType<unknown>;

export const useGetApiUserContext = <
  TData = Awaited<ReturnType<typeof getApiUserContext>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUserContext>>, TError, TData>>;
  request?: SecondParameter<typeof executeFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiUserContextQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetApiUserContext = async <
  TData = Awaited<ReturnType<typeof getApiUserContext>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUserContext>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetApiUserContextQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getApiUserContextAssume = (
  params?: GetApiUserContextAssumeParams,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<void>(
    { url: `/api/user-context/assume`, method: 'GET', params, signal },
    options,
  );
};

export const getGetApiUserContextAssumeQueryKey = (params?: GetApiUserContextAssumeParams) => {
  return [`/api/user-context/assume`, ...(params ? [params] : [])] as const;
};

export const getGetApiUserContextAssumeQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiUserContextAssume>>,
  TError = ErrorType<unknown>,
>(
  params?: GetApiUserContextAssumeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getApiUserContextAssume>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiUserContextAssumeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUserContextAssume>>> = ({
    signal,
  }) => getApiUserContextAssume(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiUserContextAssume>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApiUserContextAssumeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiUserContextAssume>>
>;
export type GetApiUserContextAssumeQueryError = ErrorType<unknown>;

export const useGetApiUserContextAssume = <
  TData = Awaited<ReturnType<typeof getApiUserContextAssume>>,
  TError = ErrorType<unknown>,
>(
  params?: GetApiUserContextAssumeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getApiUserContextAssume>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiUserContextAssumeQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetApiUserContextAssume = async <
  TData = Awaited<ReturnType<typeof getApiUserContextAssume>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params?: GetApiUserContextAssumeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getApiUserContextAssume>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetApiUserContextAssumeQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};
