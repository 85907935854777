'use client';

import { createContext, useContext, useMemo } from 'react';
import { useKey } from 'react-use';
import useToggle from '@unifyapps/hooks/useToggle';

export interface DebugContextValue {
  debug: boolean;
}

const DebugContext = createContext<null | DebugContextValue>(null);

const useDebugContext = () => {
  const context = useContext(DebugContext);

  if (!context) {
    throw new Error('DebugContext must be used within DebugContextProvider');
  }

  return context;
};

function DebugContextProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [debug, { toggle: toggleDebug }] = useToggle(false);

  useKey(
    (e) => {
      return e.key === '/' && e.shiftKey && e.ctrlKey; // Ctrl + ?
    },
    () => {
      toggleDebug();
    },
    {},
    [],
  );

  const value = useMemo(() => ({ debug }), [debug]);

  return <DebugContext.Provider value={value}>{children}</DebugContext.Provider>;
}

export { useDebugContext, DebugContextProvider };
