import type { NavItems } from '@unifyapps/carbon/components/SideNav/types';
import { createContext, useContext } from 'react';
import type { ModuleValues } from '@unifyapps/carbon/consts/modules';

export const navItemType = ['ACTION', 'URL', 'GROUP', 'USER_MENU', 'APPLICATION_PAGE'] as const;
export type NavItemType = (typeof navItemType)[number];

export interface SideNavItem {
  id: string;
  properties: NavItem;
}

export interface NavItem {
  id: string;
  name: string;
  icon: string;
  url?: string;
  type: NavItemType;
  parent?: string;
  /**
   * If the item is a module, this is the module ID
   */
  moduleId?: ModuleValues;

  /**
   * If the item is an application, this is the application ID
   */
  interfaceId?: string;

  /**
   * An application should have a page slug
   */
  interfacePageSlug?: string;

  /**
   * This tells where to render the item
   */
  placement?: 'primary' | 'secondary';
  items?: SideNavItem[];
}

export interface AppSidenavContextType {
  primary: NavItems;
  secondary: NavItems;

  isLoading: boolean;
  error: Error | null;
  assumeContextAllowed: boolean | undefined;
  customerName: string | undefined;
  loggedInUserEmail: string | undefined;
  loggedInUserName: string | undefined;
  unopenedNotificationsCount: number | undefined;
}

export const AppSidenavContext = createContext<AppSidenavContextType | null>(null);

export const useAppSidenavContext = () => {
  const context = useContext(AppSidenavContext);
  if (!context) {
    throw new Error('useAppSidenav must be used within an AppSidenavProvider');
  }
  return context;
};
