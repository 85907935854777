import { Op } from '@unifyapps/network/generated/models/op';

export enum ConnectionEditTabs {
  'Details' = 'details',
  'Settings' = 'settings',
}

export const getConnectionsRoute = () => '/connections';
export const getConnectionsDashboardRoute = () => '/connections/insights';
export const getConnectionsAllRoute = () => '/connections/all';
export const getConnectionsSettingsRoute = () => '/connections/settings';

export const getCreateConnectionRoute = () => '/connections/create';

export const getEditConnectionRoute = (connectionId: string, tabId = ConnectionEditTabs.Details) =>
  `/connections/edit/${connectionId}/${tabId}`;

export const getShareConnectionRoute = (connectionId: string) =>
  `/connections/all/share/${connectionId}`;
export const getCreateConnectionRouteForApp = (appName) => `/connections/create/${appName}`;

export const getFilteredAppNameConnectionsRoute = (appName: string) => {
  const encodedFilter = encodeURIComponent(
    JSON.stringify({
      op: Op.AND,
      values: [
        {
          field: 'appName',
          op: Op.IN,
          values: [appName],
        },
      ],
    }),
  );
  return `/connections/all?Connection_filter=${encodedFilter}`;
};
