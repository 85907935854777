'use client';
let triggered = false;

async function enableApiMocking() {
  // Uncomment to enable mocks. Disabled as it is crashing due to turbopack dev, which is fixed in next 15
  // const { worker } = await import('./browser');
  // await worker.start({ onUnhandledRequest: 'bypass' });
}

export function MockInitialization() {
  if (process.env.NEXT_PUBLIC_USE_MOCKS === 'true' && !triggered) {
    console.log('[MockInitialization] Enabling API mocking');
    triggered = true;
    // eslint-disable-next-line @typescript-eslint/no-throw-literal -- needed
    throw enableApiMocking();
  }
  return null;
}
