import './smallIconButton.css';
import { default as BaseIconButton } from '@mui/joy/IconButton';
import { forwardRef } from 'react';
import type { SmallIconButtonProps } from './types';
import { smallIconButtonVariants, smallIconButtonIconVariants } from './styles';

const SMALLICONBUTTON_ID_CLASSNAME = 'ua-smallIconButton';

const SmallIconButton = forwardRef<HTMLButtonElement, SmallIconButtonProps>(
  (props, ref): JSX.Element => {
    const {
      size,
      Icon,
      className,
      iconClassName: _iconClassName,
      variant = 'solid',
      stroke = 'currentColor',
      ...rest
    } = props;

    const iconButtonClassName = smallIconButtonVariants({
      className: [SMALLICONBUTTON_ID_CLASSNAME, className],
      size,
      variant,
    });

    const iconClassName = smallIconButtonIconVariants({
      className: _iconClassName,
      size,
    });

    return (
      <BaseIconButton ref={ref} {...rest} className={iconButtonClassName}>
        <Icon className={iconClassName} stroke={stroke} />
      </BaseIconButton>
    );
  },
);

SmallIconButton.displayName = 'SmallIconButton';

export default SmallIconButton;
