import { radioClasses } from '@mui/joy/Radio';
import type { RadioOwnerState, RadioSlot, StyleOverrides, Theme } from '@mui/joy';

export const getRadioStyleOverrides = (): StyleOverrides<RadioSlot, RadioOwnerState, Theme> => {
  return {
    root: () => [
      {
        // remove extra height
        '--Radio-size': 0,
        height: 'min-content',
      },
    ],
    radio: () => [
      {
        background: 'transparent',
      },
    ],
    icon: () => [{}],
    action: () => [
      {
        // focus ring applied to radio element via cva
        [`&.${radioClasses.focusVisible}`]: {
          outline: 'none',
        },
      },
    ],
    label: () => [{}],
  };
};
