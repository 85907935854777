'use client';

import type { MouseEventHandler } from 'react';
import React, { createContext, useContext, useMemo, useState } from 'react';

export type BreadcrumbItemType = {
  href: string;
  label: string | undefined;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export type BreadcrumbsType = {
  items: BreadcrumbItemType[];
  activeItemLabel: string | undefined;
} | null;

type ContextType = {
  breadcrumbs: BreadcrumbsType;
  setBreadcrumbs: (breadcrumbs: BreadcrumbsType) => void;
};

const NavBreadcrumbsContext = createContext<ContextType | null>(null);

export const useNavBreadcrumbsContext = () => {
  const context = useContext(NavBreadcrumbsContext);

  if (!context) {
    throw new Error('useNavBreadcrumbsContext must be used within a NavBreadcrumbsContextProvider');
  }

  return context;
};

type Props = { children: React.ReactNode };

export function NavBreadcrumbsContextProvider({ children }: Props) {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsType>(null);

  const contextValue = useMemo(() => ({ breadcrumbs, setBreadcrumbs }), [breadcrumbs]);

  return (
    <NavBreadcrumbsContext.Provider value={contextValue}>{children}</NavBreadcrumbsContext.Provider>
  );
}
