import type { StyleOverrides } from '@mui/joy';
import type { TooltipSlot } from '@mui/joy/Tooltip';
import type { Theme } from '@unifyapps/style/joy/theme';
import type { TooltipOwnerState } from './types';

export const getTooltipStyleOverrides = (): StyleOverrides<
  TooltipSlot,
  TooltipOwnerState,
  Theme
> => {
  return {
    root: () => {
      return [
        {
          '--Tooltip-arrowSize': '12px',
        },
      ];
    },
    arrow: () => {
      return [
        {
          '::before': {
            // joy uses border to show arrow, and this color is not available on border
            borderColor: 'var(--bg-primary-solid)',
          },
        },
      ];
    },
  };
};
