import type { GlobalAccessControlContextType } from '@unifyapps/carbon/access-control/context/GlobalAccessControl';
import { useGlobalAccessControl } from '@unifyapps/carbon/access-control/context/GlobalAccessControl';
import { useMemo } from 'react';
import type { ModuleValues } from '@unifyapps/carbon/consts/modules';
import { Modules, ModulesWithoutEntityPermissions } from '@unifyapps/carbon/consts/modules';
import type { ModuleConfig } from '@unifyapps/network/generated/models/moduleConfig';
import type { EntityTypeVsAssetClass } from '@/modules/user/const/permissions';
import { Permission } from '@/modules/user/const/permissions';
import { hasAccess } from '@/modules/user/utils';

const permission = Permission.VIEW;

const getEntityPermissions = (
  entities: Record<string, EntityTypeVsAssetClass>,
  permissions: GlobalAccessControlContextType,
) => {
  return Object.values(entities).reduce((acc: Record<string, boolean>, entity) => {
    acc[entity] = hasAccess(permissions, entity, permission);
    return acc;
  }, {});
};
const useModulesPermissions = (
  modulesMap: Record<string, ModuleConfig | undefined> | undefined,
) => {
  const globalPermissions = useGlobalAccessControl();
  const entityPermissions = getEntityPermissions(
    Modules as Partial<Record<ModuleValues, EntityTypeVsAssetClass>>,
    globalPermissions,
  );
  const permissionsObject = useMemo(() => {
    const permissions: Record<string, boolean> = {};
    Object.keys(Modules).forEach((moduleKey) => {
      const moduleId = Modules[moduleKey as keyof typeof Modules];
      if (ModulesWithoutEntityPermissions.includes(moduleId)) {
        permissions[moduleId] = Boolean(modulesMap?.[moduleId]?.enabled);
      } else {
        permissions[moduleId] = Boolean(
          modulesMap?.[moduleId]?.enabled && entityPermissions[moduleId],
        );
      }
    });
    return permissions;
  }, [modulesMap, entityPermissions]);
  return permissionsObject;
};

export default useModulesPermissions;
