import {
  WorkflowBuilderTabs,
  WorkflowDetailsTabs,
  WorkflowSettingsTabs,
} from '@/modules/workflows/views/WorkflowDetails/types';
import { RESTORE_VERSION } from '../hooks/useRestoreWorkflowToPreviousVersion/useRestoreVersion';

export const WORKFLOWS_BASE_ROUTE = '/automations';
export const DEFAULT_WORKFLOW_DETAIL_TAB = WorkflowDetailsTabs.Preview;
export const DEFAULT_WORKFLOW_BUILDER_TAB = WorkflowBuilderTabs.Builder;

export const NODE_QUERY_PARAM = 'activeStep';

export const getInsightsRoute = () => `${WORKFLOWS_BASE_ROUTE}/insights`;

export function getSettingsTabRoute(tabId = WorkflowSettingsTabs.Alerts) {
  return `${WORKFLOWS_BASE_ROUTE}/settings/${tabId}`;
}

export function getWorkflowTabRoute(
  workflowId: string,
  tabId: WorkflowDetailsTabs = DEFAULT_WORKFLOW_DETAIL_TAB,
) {
  return `${WORKFLOWS_BASE_ROUTE}/${workflowId}/${tabId}`;
}

export function getWorkflowBuilderRoute(
  workflowId: string,
  tabId: WorkflowBuilderTabs = DEFAULT_WORKFLOW_BUILDER_TAB,
) {
  return `${WORKFLOWS_BASE_ROUTE}/${workflowId}/${tabId}`;
}

export function getWorkflowBuilderRouteWithRestoredVersion(
  workflowId: string,
  restoreVersion: number,
) {
  return `${WORKFLOWS_BASE_ROUTE}/${workflowId}/${DEFAULT_WORKFLOW_BUILDER_TAB}?${RESTORE_VERSION}=${restoreVersion}`;
}

export function getWorkflowBuilderRouteWithActiveNode(workflowId: string, nodeId: string) {
  return `${getWorkflowBuilderRoute(workflowId)}?${NODE_QUERY_PARAM}=${nodeId}`;
}

export function getCreateAutomationRoute() {
  return `${WORKFLOWS_BASE_ROUTE}/create`;
}

export function getWorkflowTestRunRoute(workflowId: string, runId: string) {
  return `${WORKFLOWS_BASE_ROUTE}/${workflowId}/test/${runId}`;
}

export function getWorkflowRunDetailsRoute(automationId: string, runId: string) {
  return `${WORKFLOWS_BASE_ROUTE}/${automationId}/runs/${runId}`;
}

export function getWorkflowVersionDetailsRoute(automationId: string, versionNumber: string) {
  return `${WORKFLOWS_BASE_ROUTE}/${automationId}/versions/${versionNumber}`;
}
