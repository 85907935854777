import type { StylisPlugin } from '@emotion/cache';

// https://github.com/emotion-js/emotion/issues/3134#issuecomment-1939309240
// How can I wrap all the emotion styling with one @layer?
const wrapInLayer: (layerName: string) => StylisPlugin = (layerName) => (node) => {
  // if we're not at the root of a <style> tag, leave the tree intact
  if (node.parent) return;

  // if we're at the root, replace node with `@layer layerName { node }`
  const child = { ...node, parent: node, root: node };
  Object.assign(node, {
    children: [child],
    length: 6,
    parent: null,
    props: [layerName],
    return: '',
    root: null,
    type: '@layer',
    value: `@layer ${layerName}`,
  });
};

export default wrapInLayer;
