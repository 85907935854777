import type { StyleOverrides } from '@mui/joy';
import type { AvatarSlot } from '@mui/joy/Avatar';
import type { Theme } from '@unifyapps/style/joy/theme';

export const getAvatarStyleOverrides = (): StyleOverrides<AvatarSlot, unknown, Theme> => {
  return {
    root: () => {
      return [
        {
          color: 'unset',
          backgroundColor: 'unset',
          '&::after': {
            pointerEvents: 'none',
            position: 'absolute',
            inset: 0,
          },
        },
      ];
    },
    fallback: () => {
      return [
        {
          color: 'unset',
        },
      ];
    },
  };
};
