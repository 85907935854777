import { useSearchEntities } from '@unifyapps/network/generated/entity-rest-api/entity-rest-api';
import { UAEntityTypes } from '@unifyapps/defs/types/entities';
import { useMemo } from 'react';
import type { NavItems } from '@unifyapps/carbon/components/SideNav/types';
import { HTTP_HEADERS } from '@unifyapps/network/auth/const';
import { useTranslation } from '@unifyapps/i18n/client';
import { getSideNavParams } from '@/modules/sidenav/data/sidenavItems';
import {
  getAdaptedSideNavItems,
  getCategorisedSideNavItems,
  getItemsByGroup,
  getSanitisedNavItems,
  getSideNavItemsWithUrl,
} from '@/modules/sidenav/utils';
import type { SideNavItem } from '@/modules/sidenav/context/AppSidenavContext';
import useModuleRoute from '@/modules/sidenav/hooks/useModuleRoute';

export default function useEntitySidenavItems() {
  const {
    data: sideNavData,
    error,
    isLoading,
  } = useSearchEntities(UAEntityTypes.Navigation, getSideNavParams(), {
    request: {
      headers: {
        // Since navigation is global, we don't need to pass projectId so inside projects, the same navigation gets shown as in the global context
        [HTTP_HEADERS.ProjectId]: '',
      },
    },
  });

  // here we need to set final sidenav items in the provider based on fallback and search entities
  const sideNavItems = sideNavData?.objects as unknown as SideNavItem[] | undefined;

  const { getModuleRoute } = useModuleRoute();
  const { t } = useTranslation('common');

  const { primaryNavItems, secondaryNavItems } = useMemo(() => {
    const sideNavItemsWithUrl = getSideNavItemsWithUrl(t, getModuleRoute, sideNavItems);
    const sanitisedSideNavItems = getSanitisedNavItems(sideNavItemsWithUrl);
    const groupedItems = getItemsByGroup(sanitisedSideNavItems);
    const adaptedSideNavItems = getAdaptedSideNavItems(sanitisedSideNavItems, groupedItems);
    return getCategorisedSideNavItems(adaptedSideNavItems);
  }, [getModuleRoute, sideNavItems, t]) as unknown as {
    primaryNavItems: NavItems;
    secondaryNavItems: NavItems;
  };

  return {
    primaryNavItems,
    secondaryNavItems,
    isLoading,
    error,
  };
}
