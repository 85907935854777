import type { CheckboxOwnerState, StyleOverrides, Theme } from '@mui/joy';
import type { CheckboxSlot } from '@mui/joy/Checkbox/CheckboxProps';
import { checkboxClasses } from '@mui/joy/Checkbox';

export const getCheckboxStyleOverrides = (): StyleOverrides<
  CheckboxSlot,
  CheckboxOwnerState,
  Theme
> => {
  return {
    root: () => [
      {
        // remove extra height
        height: 'min-content',
      },
    ],
    checkbox: () => [{}],
    action: () => [
      {
        // focus ring applied to checkbox element via cva
        [`&.${checkboxClasses.focusVisible}`]: {
          outline: 'none',
          '--focus-thickness': 0,
        },
      },
    ],
    label: () => [{}],
    input: () => [
      {
        outlineOffset: 0,
      },
    ],
  };
};
