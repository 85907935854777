import { useMemo } from 'react';
import { useLookup } from '@unifyapps/network/generated/lookup-rest-api/lookup-rest-api';
import { LookupByQueryRequestType } from '@unifyapps/network/generated/models/lookupByQueryRequestType';
import type { Entity } from '@unifyapps/network/generated/models/entity';
import { UAEntityTypes } from '@unifyapps/defs/types/entities';
import type { NotificationCategory } from '../types';

type Response = {
  response?: {
    objects?: (Omit<Entity, 'properties'> & { properties: NotificationCategory })[];
  };
};

export const useNotificationCategories = () => {
  const { data: notificationCategories, isLoading } = useLookup<Response>({
    type: LookupByQueryRequestType.ByQuery,
    lookupType: 'ENTITY',
    options: {
      entity_type: UAEntityTypes.NotificationCategory,
    },
  });

  const categories = useMemo(
    () =>
      notificationCategories?.response?.objects?.reduce<Record<string, NotificationCategory>>(
        (acc, object) => {
          acc[object.id ?? object.properties.name] = object.properties;
          return acc;
        },
        {},
      ),
    [notificationCategories?.response?.objects],
  );

  return { categories, isLoading };
};
