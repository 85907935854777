import { EntityDetailsTabEnum } from '../types';
import type { AccessControlTabs } from '../views/EntityDetails/views/AccessControl/types';

export const getModelsManagerRoute = () => '/unified-entity-model';

export const getCreateModelRoute = () => '/unified-entity-model/create';

export const getEditModelRoute = (id: string) => `/unified-entity-model/edit/${id}`;

export const getShareModelRoute = (id: string) => `/unified-entity-model/share/${id}`;

export const getModelDetailsRoute = (id: string) => `/unified-entity-model/${id}`;

export const getCreateModelEntityRoute = (modelId: string) =>
  `/unified-entity-model/${modelId}/entities/create`;

export const getEditModelEntityRoute = (modelId: string, entityId: string) =>
  `/unified-entity-model/${modelId}/entities/edit/${entityId}`;

export const getEntityDetailsRoute = (
  modelId: string,
  entityId: string,
  tab = EntityDetailsTabEnum.Schema,
) => `/unified-entity-model/${modelId}/entities/${entityId}/${tab}`;

export const getCreateAccessControlRoute = (modelId: string, entityId: string) =>
  `${getEntityDetailsRoute(modelId, entityId, EntityDetailsTabEnum.AccessControl)}/create`;

export const getEditAccessControlRoute = (
  modelId: string,
  entityId: string,
  accessControlId: string,
) =>
  `${getEntityDetailsRoute(modelId, entityId, EntityDetailsTabEnum.AccessControl)}/edit/${accessControlId}`;

export const getAccessControlEditorRoute = (
  modelId: string,
  entityId: string,
  accessControlId: string,
  tab: AccessControlTabs,
) =>
  `${getEntityDetailsRoute(modelId, entityId, EntityDetailsTabEnum.AccessControl)}/${accessControlId}/${tab}`;
