import type { TabsOwnerState, TabsSlot } from '@mui/joy/Tabs';
import type { StyleOverrides, Theme } from '@unifyapps/style/joy/theme';

export const getTabsStyleOverrides = (): StyleOverrides<TabsSlot, TabsOwnerState, Theme> => {
  return {
    root: () => {
      return [
        {
          backgroundColor: 'transparent',
        },
      ];
    },
  };
};
