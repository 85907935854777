import type { StyleOverrides } from '@mui/joy';
import type { BreadcrumbsSlot } from '@mui/joy/Breadcrumbs';
import type { Theme } from '@unifyapps/style/joy/theme';

export const getBreadcrumbsStyleOverrides = (): StyleOverrides<BreadcrumbsSlot, unknown, Theme> => {
  return {
    root: () => {
      return [
        {
          padding: 0,
        },
      ];
    },
  };
};
