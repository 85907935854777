export const CODE_SNIPPET_BASE_ROUTE = '/code-snippets';

export const getCodeSnippetsManagerRoute = () => CODE_SNIPPET_BASE_ROUTE;

export const getCreateCodeSnippetRoute = () => `${CODE_SNIPPET_BASE_ROUTE}/create`;

export const getEditCodeSnippetRoute = (codeSnippetId: string) =>
  `${CODE_SNIPPET_BASE_ROUTE}/edit/${codeSnippetId}`;

export const getCloneCodeSnippetRoute = (codeSnippetId: string) =>
  `${CODE_SNIPPET_BASE_ROUTE}/clone/${codeSnippetId}`;

export const getShareCodeSnippetRoute = (codeSnippetId: string) =>
  `${CODE_SNIPPET_BASE_ROUTE}/share/${codeSnippetId}`;
