import { ObjectDetailsTabsEnum } from '../views/ObjectDetails/types';

const BASE_ROUTE = '/objects';

export enum ObjectsManagerTabsEnum {
  Custom = 'custom',
  Standard = 'standard',
}

export const getObjectsManagerRoute = () => BASE_ROUTE;

export const getObjectsManagerTabRoute = (tab = ObjectsManagerTabsEnum.Custom) =>
  `${BASE_ROUTE}/${tab}`;

export const getCreateObjectRoute = () => `${BASE_ROUTE}/create`;

export const getEditObjectRoute = (objectId: string) => `${BASE_ROUTE}/edit/${objectId}`;

export const getShareObjectRoute = (objectId: string) => `${BASE_ROUTE}/share/${objectId}`;

export const getObjectDetailsRoute = (objectId: string, tab = ObjectDetailsTabsEnum.Schema) =>
  `${BASE_ROUTE}/${objectId}/${tab}`;
