import type { TextareaOwnerState, TextareaSlot } from '@mui/joy/Textarea';
import type { StyleOverrides, Theme } from '@unifyapps/style/joy/theme';

export const getTextareaStyleOverrides = (): StyleOverrides<
  TextareaSlot,
  TextareaOwnerState,
  Theme
> => {
  return {
    root: () => {
      return [
        {
          '--Textarea-focusedInset': 'var(--any, )', // https://mui.com/joy-ui/react-textarea/#focused-ring
          '--Textarea-focusedThickness': '0',
          '--Textarea-paddingInline': 'unset',
          '--Textarea-paddingBlock': 'unset',
          '--Textarea-placeholderColor': 'unset',
          '--Textarea-placeholderOpacity': 1,
          boxShadow: 'none',
        },
      ];
    },
  };
};
