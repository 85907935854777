import type { CurrentUserDetails } from '@unifyapps/network/generated/models/currentUserDetails';
import { setUser } from '@sentry/react';

export function setUserContext(user: CurrentUserDetails) {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    setUser({
      id: user.user?.id,
      username: user.user?.username,
      email: user.user?.email,
      customerId: user.customer?.id,
      customerName: user.customer?.name,
    });
  } else if (
    process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID &&
    process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN
  ) {
    void import('@datadog/browser-rum').then(({ datadogRum }) =>
      datadogRum.setUser({ id: user.user?.id }),
    );
  } else {
    // local development
    console.log('setUser', user);
  }
}
