import type { StyleOverrides } from '@mui/joy';
import type { ButtonSlot } from '@mui/joy/Button';
import type { Theme } from '@unifyapps/style/joy/theme';

export const getButtonStyleOverrides = (): StyleOverrides<ButtonSlot, unknown, Theme> => {
  return {
    root: () => {
      return [
        {
          '--Button-gap': 0,
          '--focus-outline-offset': 0,
          minWidth: 'unset',
          minHeight: 'unset',
          paddingBlock: 0,
          paddingInline: 0,
          color: 'unset',
          backgroundColor: 'unset',
          ':hover': {
            backgroundColor: 'unset',
          },
          ':active': {
            backgroundColor: 'unset',
          },
          ':disabled': {
            backgroundColor: 'unset',
          },
          border: '1px solid transparent',
        },
      ];
    },
  };
};
