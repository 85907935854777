import { DCEntity } from '../const/entity';
import { AssetTabs } from '../views/DataCatalogManager/types';

export enum DataCatalogTabsEnum {
  Overview = 'overview',
  AllAssets = 'all-assets',
  DataQuality = 'data-quality',
}

export const DATA_CATALOG_BASE_ROUTE = '/data-catalog';

export const getDataCatalogTabRoute = (tabId = DataCatalogTabsEnum.Overview) => {
  return `${DATA_CATALOG_BASE_ROUTE}/${tabId}`;
};

export function getAssetSidebarTabRoute(
  dataCatalogTab: DataCatalogTabsEnum,
  assetId: string,
  assetType: string,
) {
  return `${getDataCatalogTabRoute(dataCatalogTab)}/asset/${assetType}/${assetId}/${AssetTabs.Overview}`;
}

export function getAssetDetailsTabRoute(
  assetId: string,
  assetType: string,
  tabId: AssetTabs = AssetTabs.Overview,
) {
  let query = '';

  if (assetType === (DCEntity.Table as string)) {
    query = `?pageInputs={"objectId":"${assetId}"}`;
  }

  return `${DATA_CATALOG_BASE_ROUTE}/${assetType}/${assetId}/${tabId}${query}`;
}
