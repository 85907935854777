'use client';

import React, { createContext, useContext, useMemo } from 'react';
import { HTTP_HEADERS } from '@unifyapps/network/auth/const';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import { useAggregate } from '@unifyapps/network/generated/aggregation-rest-api/aggregation-rest-api';
import type { ModuleValues } from '../../consts/modules';
import { getAggregationQuery } from './query';
import useDefaultModuleDefs from './useDefaultModuleDefs';

type ModuleDefinition = {
  id: ModuleValues;
  name: string;
  pluralName: string;
};

export interface ModuleDefsContextType {
  moduleDefs: Record<ModuleValues, ModuleDefinition>;
}

const ModuleDefsProvider = createContext<ModuleDefsContextType | null>(null);

export const useModuleDefsContext = () => {
  const context = useContext(ModuleDefsProvider);

  if (!context) {
    throw new Error('useModuleDefsContext must be used within a ModuleDefsProvider');
  }

  return context;
};

export const ModuleDefsContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data } = useAggregate(getAggregationQuery(), {
    request: {
      headers: {
        // Since navigation is global, we don't need to pass projectId so inside projects, the same navigation gets shown as in the global context
        [HTTP_HEADERS.ProjectId]: '',
      },
    },
  });

  const defaultModuleDefs = useDefaultModuleDefs();

  const value = useMemo(() => {
    const fetchedModuleDefs = _keyBy(_map(data?.objects, 'columns'), 'id');
    const moduleDefs = { ...defaultModuleDefs, ...fetchedModuleDefs };
    return { moduleDefs };
  }, [data?.objects, defaultModuleDefs]) as ModuleDefsContextType;

  return <ModuleDefsProvider.Provider value={value}>{children}</ModuleDefsProvider.Provider>;
};

export function MatrixModuleDefsProvider({ children }: { children: React.ReactNode }) {
  const defaultModuleDefs = useDefaultModuleDefs();

  const value = useMemo(
    () => ({ moduleDefs: defaultModuleDefs }) as ModuleDefsContextType,
    [defaultModuleDefs],
  );

  return <ModuleDefsProvider.Provider value={value}>{children}</ModuleDefsProvider.Provider>;
}
