import { useCallback, useMemo } from 'react';
import { clsx } from 'clsx';
import type {
  TypographyColors,
  TypographyVariants,
  TypographyWeight,
} from '@unifyapps/ui/components/Typography';
import { Typography } from '@unifyapps/ui/components/Typography';
import Stack from '@unifyapps/ui/_components/Stack';
import { useTranslation } from '@unifyapps/i18n/client';
import { FeaturedIcon } from '@unifyapps/ui/components/FeaturedIcon';
import type { FeaturedIconColors, FeaturedIconSizes } from '@unifyapps/ui/components/FeaturedIcon';
import InfoCircle from '@unifyapps/icons/outline/InfoCircle';
import { getIconFromRegistry } from '@unifyapps/icons/utils/registry';
import { Box } from '@unifyapps/ui/components/Box';
import { useMarkAsRead } from '@unifyapps/network/generated/notification-rest-api/notification-rest-api';
import { formatTime } from '../../utils/time';
import type { Notification as NotificationType, NotificationCategory } from './types';
import { NotificationActionProvider } from './NotificationActionProvider';

function TimeInterval({ createdTime }: { createdTime: number | undefined }) {
  const { t } = useTranslation(['common']);

  if (!createdTime) {
    return null;
  }

  const millisFromNow = Date.now() - createdTime;

  return (
    <Typography color="text-quaternary" variant="text-xxxs" weight="regular">
      {millisFromNow > 60 * 1000
        ? t('common:TimeAgo', { time: formatTime(millisFromNow, 'minutes', 'comfortable', 1) })
        : t('common:JustNow')}
    </Typography>
  );
}

export function NotificationContent({
  category,
  content,
  createdTime,
  className,
  typography,
  icon,
}: {
  category: NotificationCategory | undefined;
  content: string;
  createdTime?: number;
  className?: string;
  typography?: {
    variant?: TypographyVariants;
    weight?: TypographyWeight;
    color?: TypographyColors;
  };
  icon?: {
    size?: FeaturedIconSizes;
    className?: string;
  };
}) {
  const html = useMemo(() => ({ __html: content }), [content]);

  const { name, color } = category?.icon ?? { name: 'InfoCircle', color: 'gray' };

  return (
    <Stack className={clsx('gap-md', className)} direction="row">
      <FeaturedIcon
        Icon={getIconFromRegistry(name) ?? InfoCircle}
        className="!rounded-full"
        color={color as FeaturedIconColors}
        iconClassName={icon?.className ?? 'size-3'}
        size={icon?.size ?? 'xs'}
      />
      <Stack className="gap-xxs" direction="column">
        <Typography
          color={typography?.color ?? 'text-tertiary'}
          dangerouslySetInnerHTML={html}
          variant={typography?.variant ?? 'text-xs'}
          weight={typography?.weight ?? 'regular'}
        />
        <TimeInterval createdTime={createdTime} />
      </Stack>
    </Stack>
  );
}

function NotificationItem({
  content,
  createdTime,
  category,
  className,
  read,
  id,
  onClick,
  onMarkAsRead,
}: {
  content: string;
  createdTime: number | undefined;
  category: NotificationCategory | undefined;
  className?: string;
  read?: boolean;
  id: string;
  onClick: (() => void) | undefined;
  onMarkAsRead?: (id: string) => void;
}) {
  const { mutate: markAsRead } = useMarkAsRead({
    mutation: {
      onSuccess: () => onMarkAsRead?.(id),
    },
  });

  const handleClick = useCallback(() => {
    markAsRead({ params: { notificationId: id } });
    onClick?.();
  }, [markAsRead, id, onClick]);

  return (
    <Stack
      className={clsx(
        'rounded-sm',
        {
          'hover:bg-primary_hover cursor-pointer': Boolean(onClick),
        },
        className,
      )}
      direction="column"
      onClick={handleClick}
    >
      <Stack className="gap-sm justify-between" direction="row">
        <NotificationContent category={category} content={content} createdTime={createdTime} />
        {read ? null : <Box className="bg-brand-solid h-md w-md mt-0.5 shrink-0 rounded-full" />}
      </Stack>
    </Stack>
  );
}

function NotificationItemWrapper({
  notification,
  className,
  onMarkAsRead,
}: {
  notification: NotificationType;
  className?: string;
  onMarkAsRead?: (id: string) => void;
}) {
  if (!notification.content || !notification.id) {
    return null;
  }

  return (
    <NotificationActionProvider cta={notification.cta}>
      {({ onClick }: { onClick: (() => void) | undefined }) => (
        <NotificationItem
          category={notification.category}
          onClick={onClick}
          onMarkAsRead={onMarkAsRead}
          read={notification.read}
          className={className}
          //@ts-expect-error -- checked above
          content={notification.content}
          createdTime={notification.createdTime}
          //@ts-expect-error -- checked above
          id={notification.id}
        />
      )}
    </NotificationActionProvider>
  );
}

export { NotificationItemWrapper as NotificationItem };
