/* eslint-disable @typescript-eslint/no-shadow -- From mui example, refer permalink */
/**
 * https://github.com/mui/material-ui/blob/4398f9b92900cc4a2bbcee4fb2aec29a293d3672/examples/joy-ui-nextjs-ts/src/components/ThemeRegistry/EmotionCache.tsx
 */

'use client';
import * as React from 'react';
import type { EmotionCache, Options as OptionsOfCreateCache } from '@emotion/cache';
import createCache from '@emotion/cache';
import { useServerInsertedHTML } from '@unifyapps/carbon/navigation';
import { CacheProvider as DefaultCacheProvider } from '@emotion/react';
import wrapInLayer from '@unifyapps/style/joy/wrapInLayer';

export interface NextAppDirEmotionCacheProviderProps {
  /** This is the options passed to createCache() from 'import createCache from "\@emotion/cache"' */
  options: Omit<OptionsOfCreateCache, 'insertionPoint' | 'stylisPlugins'>;
  /** By default, <CacheProvider /> from 'import \{ CacheProvider \} from "\@emotion/react"' */
  CacheProvider?: (props: {
    value: EmotionCache;
    children: React.ReactNode;
  }) => React.JSX.Element | null;
  children: React.ReactNode;
}

// Adapted from https://github.com/garronej/tss-react/blob/main/src/next/appDir.tsx
export default function NextAppDirEmotionCacheProvider(
  props: NextAppDirEmotionCacheProviderProps,
): React.JSX.Element {
  const { options, CacheProvider = DefaultCacheProvider, children } = props;

  const [{ cache, flush }] = React.useState(() => {
    const cache = createCache({
      ...options,
      stylisPlugins: [wrapInLayer('emotion')],
    });
    cache.compat = true;
    const prevInsert = cache.insert;
    let inserted: { name: string; isGlobal: boolean }[] = [];
    cache.insert = (...args) => {
      const [selector, serialized] = args;
      if (cache.inserted[serialized.name] === undefined) {
        inserted.push({
          name: serialized.name,
          isGlobal: selector === '',
        });
      }
      return prevInsert(...args);
    };
    const flush = () => {
      const prevInserted = inserted;
      inserted = [];
      return prevInserted;
    };
    return { cache, flush };
  });

  useServerInsertedHTML(() => {
    const inserted = flush();
    if (inserted.length === 0) {
      return null;
    }
    let styles = '';
    let dataEmotionAttribute = cache.key;

    const globals: {
      name: string;
      style: string;
    }[] = [];

    for (const { name, isGlobal } of inserted) {
      const style = cache.inserted[name];

      if (typeof style === 'boolean') {
        continue;
      }

      if (isGlobal && style) {
        globals.push({ name, style });
      } else {
        styles += style;
        dataEmotionAttribute += ` ${name}`;
      }
    }

    return (
      <>
        {globals.map(({ name, style }) => (
          <style
            dangerouslySetInnerHTML={{ __html: style }}
            data-emotion={`${cache.key}-global ${name}`}
            key={name}
            nonce={options.nonce}
          />
        ))}
        {styles !== '' && (
          <style
            dangerouslySetInnerHTML={{
              __html: styles,
            }}
            data-emotion={dataEmotionAttribute}
            nonce={options.nonce}
          />
        )}
      </>
    );
  });

  return <CacheProvider value={cache}>{children}</CacheProvider>;
}
