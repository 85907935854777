import type { InputOwnerState, InputSlot } from '@mui/joy/Input';
import type { StyleOverrides, Theme } from '@unifyapps/style/joy/theme';

export const getInputStyleOverrides = (): StyleOverrides<InputSlot, InputOwnerState, Theme> => {
  return {
    root: () => {
      return [
        {
          '--Input-focusedInset': 'var(--any, )', // https://mui.com/joy-ui/react-input/#focused-ring
          '--Input-focusedThickness': '0',
          '--Input-gap': '0',
          '--Input-placeholderColor': 'unset',
          '--Input-placeholderOpacity': 1,
          '--Input-paddingInline': '0px',
          '--Input-minHeight': '0px',
          '--variant-borderWidth': '0',
          boxShadow: 'none',
          '& input[type="time"]::-webkit-calendar-picker-indicator': {
            display: 'none',
            WebkitAppearance: 'none',
          },
          '& input[type="date"]::-webkit-inner-spin-button, & input[type="date"]::-webkit-calendar-picker-indicator':
            {
              display: 'none',
              WebkitAppearance: 'none',
            },
          '& input[type="datetime-local"]::-webkit-calendar-picker-indicator': {
            display: 'none',
            WebkitAppearance: 'none',
          },
        },
      ];
    },
    input: () => {
      return [
        {
          border: 'none',
        },
      ];
    },
  };
};
