export type PipelineDetailsContainerProps = { pipelineId: string; tabId?: PipeLineTabs };

export enum PipeLineTabs {
  'Activity' = 'activity',
  'Summary' = 'summary',
  'SourceAndDestination' = 'source-destination',
  'Objects' = 'objects',
  'Schema' = 'schema',
  'Transformations' = 'transformations',
  'Settings' = 'settings',
  'Logs' = 'logs',
  'Runs' = 'runs',
  'Versions' = 'versions',
  'Notes' = 'notes',
  'Alerts' = 'alerts',
}

/* eslint-disable @typescript-eslint/naming-convention -- needed */
export enum PipelineValidationTypes {
  PIPELINE_OBJECT = 'PipelineObject',
  SCHEMA_MAPPING = 'SchemaMapping',
  SOURCE_FIELD = 'SourceField',
  DESTINATION_FIELD = 'DestinationField',
  TRANSFORMATION = 'Transformation',
  FILTER_FIELD = 'FilterField',
}
