'use client';

import type { PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import { getIdWithPrefix } from '@unifyapps/carbon/utils/id';
import _noop from 'lodash/noop';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import userStore from '@unifyapps/carbon/auth/userStore';
import _forEach from 'lodash/forEach';
import _omit from 'lodash/omit';
import { safeJsonParse } from '@unifyapps/carbon/utils/json';
import useMqttSubscription from '@unifyapps/network/hooks/useMqttSubscription';

export type GlobalEvent = {
  assetId: string;
  assetClass: string;
};

type Map = { filter: (event: GlobalEvent) => boolean; callback: (event: GlobalEvent) => void };
type GlobalUpdatesProviderContextType = {
  register: (id: string, map: Map) => void;
  unregister: (id: string) => void;
};
const GlobalUpdatesProviderContext = createContext<GlobalUpdatesProviderContextType>({
  register: _noop,
  unregister: _noop,
});

function useGlobalUpdatesTopic() {
  const customerId = userStore.use.currentUserDetails()?.customer?.id;
  return customerId ? `${customerId}/change_events` : undefined;
}

export function GlobalUpdatesProvider(props: PropsWithChildren) {
  const updatesTopic = useGlobalUpdatesTopic();
  const callbackMap = useRef<Record<string, Map>>({});

  const _register = useEventCallback<GlobalUpdatesProviderContextType['register']>((id, map) => {
    callbackMap.current[id] = map;
  });

  const _unregister = useEventCallback<GlobalUpdatesProviderContextType['unregister']>((id) => {
    callbackMap.current = _omit(callbackMap.current, id);
  });

  const _onMessage = useEventCallback((payload: string | Buffer) => {
    const message = safeJsonParse<GlobalEvent>(payload.toString() || '{}');
    console.debug('[MQTT] GlobalUpdatesProvider: message received', message);
    _forEach(callbackMap.current, (config) => {
      if (config.filter(message)) {
        config.callback(message);
      }
    });
  });

  useMqttSubscription(updatesTopic, { onMessage: _onMessage });

  const providerValue = useMemo(
    () => ({ register: _register, unregister: _unregister }),
    [_register, _unregister],
  );

  return (
    <GlobalUpdatesProviderContext.Provider value={providerValue}>
      {props.children}
    </GlobalUpdatesProviderContext.Provider>
  );
}

export function useGlobalUpdates(filter: Map['filter'], callback: Map['callback']) {
  const id = useMemo(() => getIdWithPrefix(), []);
  const { register, unregister } = useContext(GlobalUpdatesProviderContext);

  useEffect(() => {
    register(id, { filter, callback });
    return () => unregister(id);
  }, [callback, filter, id, register, unregister]);
}
