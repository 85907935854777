// https://aronschueler.de/blog/2022/12/16/generating-meaningful-issues-in-sentry-with-react-query-+-axios/

'use client';

import React, { useMemo, useState } from 'react';
import { useKey } from 'react-use';
import { useParams } from 'next/navigation';
import type { QueryKey } from '@tanstack/react-query';
import {
  hashKey,
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { Hub, Scope } from '@sentry/types';
import { UAError } from './consts/error';
import type { UAFetchError } from './fetch/UAFetchError';

export type QueryProviderProps = {
  children: React.ReactNode;
  withScope?: Hub['withScope'];
  captureException?: Scope['captureException'];
};

export function QueryProvider({ children, withScope, captureException }: QueryProviderProps) {
  const params = useParams<{ projectSlug?: string }>();
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- needed as params wont be defined in delta-matrix. TBD more reliable fix
  const projectSlug = params?.projectSlug;

  const queryCache = useMemo(
    () =>
      new QueryCache({
        onError: (error, query) => {
          console.error(error);
          if (captureException && withScope) {
            withScope((scope) => {
              scope.setContext('query', { queryHash: query.queryHash });
              scope.setFingerprint([query.queryHash.replaceAll(/\d/g, '0')]);
              captureException(error);
            });
          }
        },
      }),
    [captureException, withScope],
  );

  const mutationCache = useMemo(
    () =>
      new MutationCache({
        onError: (error, _variables, _context, mutation) => {
          console.error(error);
          if (captureException && withScope) {
            withScope((scope) => {
              scope.setContext('mutation', {
                mutationId: mutation.mutationId,
                variables: mutation.state.variables,
              });
              if (mutation.options.mutationKey) {
                scope.setFingerprint(Array.from(mutation.options.mutationKey) as string[]);
              }
              captureException(error);
            });
          }
        },
      }),
    [captureException, withScope],
  );

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            queryKeyHashFn: (queryKey: QueryKey) => {
              return projectSlug ? hashKey([projectSlug, queryKey]) : hashKey(queryKey);
            },
            staleTime: 1000 * 5, // Setting queries to be stale only for 5 seconds. For SSR purposes only
            retryDelay: () => 1500,
            retry: (failureCount, error: UAFetchError) => {
              if (failureCount >= 1) {
                return false;
              }

              // do not retry if the error status is not present
              if (!('status' in error) || typeof error.status !== 'number') return false;

              // do not retry if the error if error code is forbidden
              if (error.payload?.errorCode === UAError.FORBIDDEN) return false;

              // retry only for server errors 500+
              return error.status >= 500;
            },
          },
        },
        queryCache,
        mutationCache,
      }),
    [mutationCache, projectSlug, queryCache],
  );
  const [enableDevTools, setEnableDevTools] = useState(false);

  useKey(
    (e) => {
      const target = e.target as HTMLElement | null;
      if (target?.tagName !== 'BODY') return false;

      return e.key === 'T' && e.shiftKey && e.ctrlKey;
    },
    () => {
      setEnableDevTools((it) => !it);
    },
  );

  return (
    <QueryClientProvider client={queryClient}>
      {enableDevTools ? <ReactQueryDevtools initialIsOpen /> : null}
      {children}
    </QueryClientProvider>
  );
}
