import { ProjectWorkspaceSidebarTab } from '../types';

export const BASE_ROUTE = '/projects';
export const DETAILS_BASE_ROUTE = '/p';

export const getProjectsManagerRoute = () => BASE_ROUTE;

export const getCreateProjectRoute = () => `${BASE_ROUTE}/create`;

export const getEditProjectRoute = (projectId: string) => `${BASE_ROUTE}/edit/${projectId}`;

export const getShareProjectRoute = (projectId: string) => `${BASE_ROUTE}/share/${projectId}`;

export const getProjectDetailsRoute = (
  projectId: string,
  tab = ProjectWorkspaceSidebarTab.Overview,
) => `${DETAILS_BASE_ROUTE}/${projectId}/${tab}`;

export const getProjectDetailsShareRoute = (projectId: string) =>
  `${DETAILS_BASE_ROUTE}/${projectId}/${ProjectWorkspaceSidebarTab.Overview}/add-collaborator`;
