import {
  getGetUnopenedNotificationsCountQueryKey,
  useGetUnopenedNotificationsCount,
} from '@unifyapps/network/generated/notification-rest-api/notification-rest-api';
import { useQueryClient } from '@unifyapps/network/react-query';
import { useUserInboxSubscription } from './useUserInboxSubscription';

const unOpenedNotificationsCountQueryKey = getGetUnopenedNotificationsCountQueryKey();

export const useUnopenedNotifications = ({ loggedInUserId }: { loggedInUserId?: string }) => {
  const queryClient = useQueryClient();

  const { data: unopenedNotificationsCount } = useGetUnopenedNotificationsCount({
    query: { enabled: Boolean(loggedInUserId) },
  });

  useUserInboxSubscription({
    loggedInUserId,
    onNotificationReceive: () => {
      // On receiving a new notification, invalidate the unopened notifications count query so that the dot icon on the bell icon is updated
      void queryClient.invalidateQueries({ queryKey: unOpenedNotificationsCountQueryKey });
    },
  });

  return {
    unopenedNotificationsCount,
  };
};
