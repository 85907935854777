import { Op } from '@unifyapps/network/generated/models/op';
import type { AggregationQuery } from '@unifyapps/network/generated/models/aggregationQuery';
import { Modules } from '../../consts/modules';
import { AggregationQueryBuilder } from '../../utils/builders/aggregationQuery';

function createAggregationQuery(moduleIds: string[]) {
  return new AggregationQueryBuilder()
    .withEntityType('EntityType')
    .withGroup('STANDARD')
    .withProjections([
      {
        name: 'name',
      },
      {
        name: 'pluralName',
      },
      {
        name: 'id',
      },
    ])
    .withFilter({
      op: Op.AND,
      values: [
        {
          op: Op.EQUAL,
          field: 'standard',
          values: [true],
        },
        {
          op: Op.IN,
          field: 'id',
          values: moduleIds,
        },
      ],
    })
    .build();
}

export function getAggregationQuery(): AggregationQuery {
  const moduleIds = Object.values(Modules);
  const query = createAggregationQuery(moduleIds);
  return {
    ...query,
    ...{
      page: {
        offset: 0,
        limit: 1000,
      },
    },
  };
}
